import PropTypes from 'prop-types';
import { MapLayer, withLeaflet } from 'react-leaflet';
import { Icon, LatLng, Marker as LeafletMarker } from 'leaflet';
import svgToTinyDataUri from 'mini-svg-data-uri/index';

import 'leaflet.marker.slideto/Leaflet.Marker.SlideTo.js';
import 'leaflet-rotatedmarker/leaflet.rotatedMarker.js';

const carSVG = (color) => `<svg id="Layer_1" data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 44 44">
  <defs>
    <style>.cls-1{fill:#fff}.cls-2{fill:#d8d8d8;stroke:#303135}.cls-6{fill:#161718}.cls-8{fill:#acfffc}.cls-20{fill:#ff0424;stroke:#878787;stroke-width:.4px}</style>
    <linearGradient id="linear-gradient" x1="-248.62" y1="299.5" x2="-247.52" y2="299.5" gradientTransform="matrix(21 0 0 -44 5232 13199.88)" gradientUnits="userSpaceOnUse">
      <stop offset="0"/>
      <stop offset=".08" stop-color="#fff"/>
      <stop offset=".91" stop-color="#fff"/>
      <stop offset="1"/>
    </linearGradient>
    <filter id="filter-7" x="-9" y="-4.3" width="118.1" height="108.6">
      <feMorphology result="shadowSpreadInner1" radius="1" in="SourceAlpha"/>
      <feGaussianBlur result="shadowBlurInner1" stdDeviation="1" in="shadowSpreadInner1"/>
      <feOffset result="shadowOffsetInner1" in="shadowBlurInner1"/>
      <feComposite result="shadowInnerInner1" operator="arithmetic" k2="-1" k3="1" in="shadowOffsetInner1" in2="SourceAlpha"/>
      <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" in="shadowInnerInner1"/>
    </filter>
    <filter id="filter-9" x="-97.5" y="-130" width="295" height="360">
      <feMorphology result="shadowSpreadOuter1" operator="dilate" radius=".15" in="SourceAlpha"/>
      <feOffset result="shadowOffsetOuter1" in="shadowSpreadOuter1"/>
      <feGaussianBlur result="shadowBlurOuter1" stdDeviation=".5" in="shadowOffsetOuter1"/>
      <feColorMatrix values="0 0 0 0 0.45 0 0 0 0 0.99 0 0 0 0 1 0 0 0 0.5 0" in="shadowBlurOuter1"/>
    </filter>
    <filter id="filter-11" x="-97.5" y="-130" width="295" height="360">
      <feMorphology result="shadowSpreadOuter1" operator="dilate" radius=".15" in="SourceAlpha"/>
      <feOffset result="shadowOffsetOuter1" in="shadowSpreadOuter1"/>
      <feGaussianBlur result="shadowBlurOuter1" stdDeviation=".5" in="shadowOffsetOuter1"/>
      <feColorMatrix values="0 0 0 0 0.45 0 0 0 0 0.99 0 0 0 0 1 0 0 0 0.5 0" in="shadowBlurOuter1"/>
    </filter>
    <linearGradient id="linear-gradient-2" x1="-243.35" y1="444.03" x2="-242.91" y2="443.45" gradientTransform="matrix(14.96 0 0 1.89 3659.51 -796.46)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#464c52"/>
      <stop offset="1" stop-color="#212529"/>
    </linearGradient>
    <linearGradient id="linear-gradient-3" x1="22.5" y1="5.91" x2="22.5" y2="5.91" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-4" x1="25.82" y1="6.47" x2="27.21" y2="13.26" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-5" x1="22.5" y1="5.91" x2="22.5" y2="5.91" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-6" x1="17.85" y1="7.41" x2="19.13" y2="13.64" xlink:href="#linear-gradient-2"/>
    <mask id="mask" x="-.84" y="0" width="41.34" height="44" maskUnits="userSpaceOnUse">
      <g id="mask-2">
        <path id="path-1" class="cls-1" d="M17 0h11a5 5 0 0 1 5 5v33a6 6 0 0 1-6 6h-9a6 6 0 0 1-6-6V5a5 5 0 0 1 5-5z"/>
      </g>
    </mask>
    <mask id="mask-2-2" x="11.6" y="0" width="21.4" height="44.4" maskUnits="userSpaceOnUse">
      <g id="mask-2-3" data-name="mask-2">
        <path id="path-1-2" data-name="path-1" class="cls-1" d="M17 0h11a5 5 0 0 1 5 5v33a6 6 0 0 1-6 6h-9a6 6 0 0 1-6-6V5a5 5 0 0 1 5-5z"/>
      </g>
    </mask>
    <mask id="mask-3" x="12" y="0" width="21.41" height="44.4" maskUnits="userSpaceOnUse">
      <g id="mask-2-4" data-name="mask-2">
        <path id="path-1-3" data-name="path-1" class="cls-1" d="M17 0h11a5 5 0 0 1 5 5v33a6 6 0 0 1-6 6h-9a6 6 0 0 1-6-6V5a5 5 0 0 1 5-5z"/>
      </g>
    </mask>
    <linearGradient id="linear-gradient-7" x1="-163.41" y1="295.41" x2="-164.72" y2="295.39" gradientTransform="matrix(2.7 0 0 -26.01 457.75 7709.44)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#212529"/>
      <stop offset="1" stop-color="#464c52"/>
    </linearGradient>
    <linearGradient id="linear-gradient-8" x1="-163.43" y1="295.39" x2="-164.73" y2="295.38" gradientTransform="matrix(2.7 0 0 -26.01 473.17 7709.44)" xlink:href="#linear-gradient-7"/>
  </defs>
  <g id="Map_Shuttles_stellwerk-shuttle" data-name="Map/Shuttles/stellwerk-shuttle">
    <g id="Shuttle">
      <g id="Group">
        <g id="iconShuttlevan">
          <path id="tyre" class="cls-2" d="M12 5.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5V6a.5.5 0 0 1 .5-.5z"/>
          <path id="tyre-2" data-name="tyre" class="cls-2" d="M33 5.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5V6a.5.5 0 0 1 .5-.5z"/>
          <path id="tyre-3" data-name="tyre" class="cls-2" d="M12 32.2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5z"/>
          <path id="tyre-4" data-name="tyre" class="cls-2" d="M33 32.2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5z"/>
          <g id="Mask-4" data-name="Mask">
            <path id="path-1-4" data-name="path-1" class="cls-1" d="M17 0h11a5 5 0 0 1 5 5v33a6 6 0 0 1-6 6h-9a6 6 0 0 1-6-6V5a5 5 0 0 1 5-5z"/>
          </g>
        </g>
        <path id="Mask-Copy-2" d="M17 0h11a5 5 0 0 1 5 5v33a6 6 0 0 1-6 6h-9a6 6 0 0 1-6-6V5a5 5 0 0 1 5-5z" style="isolation:isolate" opacity=".73" fill="url(#linear-gradient)"/>
        <g filter="url(#filter-7)" id="Mask-Copy-3" opacity=".58">
          <rect id="path-6" x="14.19" y="7.19" width="16.61" height="34.81" rx="4" ry="4"/>
        </g>
        <g id="headlights">
          <g id="Group-3-Copy">
            <path id="Path-3" class="cls-6" d="M29.78 1.05a2.57 2.57 0 0 1 2 2.25c.12 1.22-1.57.5-2.92.19s-.2-2.82.92-2.44z"/>
            <g id="Oval-Copy">
              <g filter="url(#filter-9)">
                <circle id="path-8" cx="30" cy="2.55" r="1"/>
              </g>
              <circle id="path-8-2" data-name="path-8" class="cls-8" cx="30" cy="2.55" r="1"/>
            </g>
          </g>
          <g id="Group-3-Copy-2">
            <path id="Path-3-2" data-name="Path-3" class="cls-6" d="M15.35 1.05a2.64 2.64 0 0 0-2.11 2.25c-.13 1.22 1.68.5 3.12.19s.19-2.82-1.01-2.44z"/>
            <g id="Oval">
              <g filter="url(#filter-11)">
                <circle id="path-10" cx="15" cy="2.55" r="1"/>
              </g>
              <circle id="path-10-2" data-name="path-10" class="cls-8" cx="15" cy="2.55" r="1"/>
            </g>
          </g>
        </g>
        <path id="window" d="M15.35 41.3a30.23 30.23 0 0 0 7.15.59 30.17 30.17 0 0 0 7.15-.59.45.45 0 0 0 .31-.56.43.43 0 0 0-.21-.27A3.6 3.6 0 0 0 28 40H17.07a3.74 3.74 0 0 0-1.82.47.45.45 0 0 0-.18.61.44.44 0 0 0 .28.22z" fill="url(#linear-gradient-2)"/>
        <path fill="url(#linear-gradient-3)" d="M22.5 5.91z"/>
        <path d="M22.5 5.91v9c5 0 6 1 6.92-.57 1.14-1.92 2.07-3.94 2.07-3.94 0-1.23.51-4.49-8.99-4.49z" fill="url(#linear-gradient-4)"/>
        <path fill="url(#linear-gradient-5)" d="M22.5 5.91z"/>
        <path d="M22.5 5.91c-9.53 0-9 3.26-9 4.5 0 0 .94 2 2.07 3.94.92 1.54 1.89.57 6.93.57z" fill="url(#linear-gradient-6)"/>
        <path id="Rectangle" d="M17.5 10h3a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5z" fill="#5b6569"/>
        <path id="Rectangle-3" d="M19.05 2h7.11a1 1 0 0 0 .68-.27l.25-.23a.3.3 0 0 0 0-.41.27.27 0 0 0-.2-.09h-8.61a.28.28 0 0 0-.18.5l.3.26a1 1 0 0 0 .65.24z" fill="#090909"/>
        <g mask="url(#mask)">
          <g id="K">
            <g id="K-2" data-name="K">
              <path id="COLORLAYER" d="M19.34 19.4h1.23a9.31 9.31 0 0 1 1.27.2 9.51 9.51 0 0 1 2.25.83 8.68 8.68 0 0 1 1.37.89 4.15 4.15 0 0 1 .32.27l3.53 3.23.1.1c-2.06-1.85-5.84-1-9.41 2.44l-.32.29-13 12-.37.35a6.26 6.26 0 0 1-.76.6 4.8 4.8 0 0 1-.7.39 3.52 3.52 0 0 1-.82.25 4 4 0 0 1-1.24 0 3.95 3.95 0 0 1-2-.79 3.85 3.85 0 0 1-1.07-1.22 4.48 4.48 0 0 1-.5-1.44 5.05 5.05 0 0 1-.06-.67V37a4 4 0 0 1 .09-.69 3.57 3.57 0 0 1 .1-.31 5 5 0 0 1 1-1.57l.24-.27.25-.26.16-.21q5.74-5.26 11.5-10.54l1.19-1.09.49-.45a4.82 4.82 0 0 1 .43-.36 7.52 7.52 0 0 1 1.26-.81 9.18 9.18 0 0 1 2-.79 8.74 8.74 0 0 1 1.47-.25zm13.91 20.3l-11.9-11.08c2.4-3.37 7.25-4.15 9.27-2.28l.12.12 2.61 2.4 5.41 5 .3.31.24.26a4.91 4.91 0 0 1 1 1.64c0 .11.07.21.09.32a5.07 5.07 0 0 1 .09.54 5.3 5.3 0 0 1 0 .69c0 .17 0 .34-.08.52a4.24 4.24 0 0 1-.64 1.48 3.77 3.77 0 0 1-1 1 3.88 3.88 0 0 1-.85.46 4.1 4.1 0 0 1-1.25.26 3.87 3.87 0 0 1-.68 0 3.45 3.45 0 0 1-.77-.18 3.69 3.69 0 0 1-.75-.36 5.91 5.91 0 0 1-1-.76l-.13-.13z" fill="${color}"/>
            </g>
          </g>
        </g>
        <g mask="url(#mask-2-2)">
          <path id="Oval-2" class="cls-20" d="M14.29 44.2c-1.08 0-2.49-.82-2.49-1.7a1.7 1.7 0 0 1 3.4 0v.26a2.28 2.28 0 0 1-.09 1 .79.79 0 0 1-.82.44z"/>
        </g>
        <g mask="url(#mask-3)">
          <path id="Oval-2-Copy" class="cls-20" d="M30.72 44.2c1.07 0 2.49-.82 2.49-1.7a1.7 1.7 0 0 0-3.4 0v.26a2.47 2.47 0 0 0 .09 1 .79.79 0 0 0 .82.44z"/>
        </g>
        <path id="Rectangle-5" d="M13.5 13.45l2.5 3.07a1 1 0 0 1 .22.68q-.82 18.71-1 20.59c-.17 1.91-1.72 1.66-1.72 1.66z" fill="url(#linear-gradient-7)"/>
        <path id="Rectangle-5-Copy" d="M31.5 13.45v26s-1.55.25-1.72-1.66q-.15-1.87-1-20.61a1 1 0 0 1 .22-.65z" fill="url(#linear-gradient-8)"/>
      </g>
    </g>
  </g>
</svg>`;

class Vehicle extends MapLayer {
  buildVehicleIcon(color) {
    return new Icon({
      iconUrl: svgToTinyDataUri(carSVG(color)),
      iconSize: [44, 44],
      iconAnchor: [22, 22]
    });
  }

  createLeafletElement(props) {
    const icon = this.buildVehicleIcon(props.color);
    const modifiedProps = {
      ...props,
      icon,
      rotationAngle: props.heading,
      rotationOrigin: 'center center',
      zIndexOffset: 3600,
      type: 'vehicle'
    };

    return new LeafletMarker(props.position, this.getOptions(modifiedProps)).bindTooltip(
      `<i class="fa fa-car mr-1"></i> ${props.vehicle.license_plate} - ${props.vehicle.nickname}`,
      { direction: 'top', offset: [0, -22] }
    );
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.color !== fromProps.color) {
      this.leafletElement.setIcon(this.buildVehicleIcon(toProps.color));
    }

    if (toProps.heading !== fromProps.heading) {
      this.leafletElement.setRotationAngle(toProps.heading);
    }

    if (toProps.position !== fromProps.position) {
      this.leafletElement.slideCancel();
      if (this.props.animated) {
        // Sliding 11 seconds, a bit slower than the new vehicle positions coming in
        this.leafletElement.slideTo(toProps.position, { duration: this.props.animationDuration * 1000 });
      } else {
        this.leafletElement.setLatLng(toProps.position);
      }
    }
  }
}

Vehicle.defaultProps = {
  animated: true,
  animationDuration: 11
};

Vehicle.propTypes = {
  color: PropTypes.string.isRequired,
  heading: PropTypes.number.isRequired,
  position: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(LatLng),
    PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })
  ]).isRequired,
  animated: PropTypes.bool,
  animationDuration: PropTypes.number,
  vehicle: PropTypes.shape({
    license_plate: PropTypes.string,
    nickname: PropTypes.string
  })
};

export default withLeaflet(Vehicle);
