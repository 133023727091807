import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import uploader from '../lib/image_upload';

const SUCCESS_MESSAGE_DURATION = 3 * 1000;

const dropZoneProps = {
  accept: 'image/jpeg, image/jpg, image/gif, image/png',
  inputProps: {},
  name: 'image_upload',
  multiple: false,
  className: 'dropzone'
};

const buildDefaultState = ({ currentId, currentUrl }) => {
  const state = {
    currentId,
    currentUrl,
    uploadOngoing: false,
    progressBarPct: 0,
    progressBarText: '',
    errors: false,
    successMessage: false,
    files: []
  };

  return state;
};

export default class ImageUploader extends React.Component {
  constructor(props) {
    super(props);

    this.t = window.locales.imageUploader;

    this.state = buildDefaultState({
      currentId: props.currentId,
      currentUrl: props.currentUrl
    });

    this.handleDrop = this.handleDrop.bind(this);
    this.reset = this.reset.bind(this);
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleUploadFinished = this.handleUploadFinished.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.resetSuccessTimeout = null;
  }

  reset(e) {
    e.preventDefault();

    this.setState(
      buildDefaultState({
        currentId: this.props.currentId,
        currentUrl: this.props.currentUrl
      })
    );
  }

  handleDrop(files) {
    const file = files[0];
    if (!file) return;

    this.setState({
      files,
      uploadOngoing: true,
      errors: false
    });

    uploader
      .perform(file)
      .then(this.handleUploadSuccess)
      .progress(this.handleUploadProgress)
      .catch(this.handleUploadError)
      .always(this.handleUploadFinished);
  }

  handleUploadSuccess(response) {
    this.resetSuccessTimeout = window.setTimeout(() => {
      this.setState({ successMessage: false });
    }, SUCCESS_MESSAGE_DURATION);

    this.setState({
      currentId: response.data.id,
      currentUrl: response.data.versions.small.url,
      successMessage: this.t.successMessage
    });
  }

  handleUploadError(xhr) {
    const errors = xhr.responseJSON;
    this.setState({ errors });
  }

  handleUploadProgress({ progress }) {
    let progressBarText = `${progress}%`;

    if (progress === 100) {
      progressBarText = this.t.processing;
    }

    this.setState({ progressBarText, progressBarPct: progress });
  }

  handleUploadFinished() {
    this.setState({ uploadOngoing: false });
  }

  renderCurrentImage() {
    if (!this.state.currentUrl) return null;

    return (
      <img
        className="current-image rounded d-flex align-self-start mr-3"
        alt={this.t.altText}
        src={this.state.currentUrl}
      />
    );
  }

  renderDropZone() {
    if (this.state.uploadOngoing) return null;

    return (
      <Dropzone onDrop={this.handleDrop} {...dropZoneProps}>
        <p className="text-center mt-4">
          <i className="fa fa-cloud-upload fa-5x" />
        </p>
      </Dropzone>
    );
  }

  renderProgress() {
    if (!this.state.uploadOngoing) return null;

    return (
      <div className="progress">
        <div className="progress-bar progress-bar-striped" style={{ width: `${this.state.progressBarPct}%` }}>
          {this.state.progressBarText}
        </div>
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.successMessage) return null;

    return (
      <div className="alert alert-success">
        <strong>{this.state.successMessage}</strong>
      </div>
    );
  }

  renderErrors() {
    if (!this.state.errors) return null;

    let errorItems;
    if (this.state.errors.image) {
      const errors = this.state.errors.image;
      errorItems = errors.map((error) => <li key={error}>{error}</li>);
    } else {
      const errors = this.state.errors.api_errors;
      errorItems = errors.map((error) => <li key={error.code}>{error.message}</li>);
    }

    return (
      <div className="alert alert-danger">
        <strong>{this.t.errorHeader}</strong>
        <ul>{errorItems}</ul>
      </div>
    );
  }

  renderResetLink() {
    if (this.state.currentId === this.props.currentId) return null;

    return (
      <p className="text-right">
        <button type="button" className="btn btn-link" onClick={this.reset}>
          {this.t.resetLink}
        </button>
      </p>
    );
  }

  render() {
    return (
      <div>
        <input type="hidden" readOnly name={this.props.fieldName} value={this.state.currentId} />
        <div className="media">
          {this.renderCurrentImage()}
          <div className="media-body">
            {this.renderDropZone()}
            {this.renderProgress()}
            {this.renderResetLink()}
            {this.renderSuccess()}
            {this.renderErrors()}
          </div>
        </div>
      </div>
    );
  }
}

ImageUploader.defaultProps = {
  currentId: '',
  currentUrl: ''
};

ImageUploader.propTypes = {
  currentId: PropTypes.string,
  currentUrl: PropTypes.string,
  fieldName: PropTypes.string.isRequired
};
