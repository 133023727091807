import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

export default class ConversationItem extends Component {
  constructor(props) {
    super(props);

    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  handleLinkClick() {
    this.props.onClick(this.props.number);
  }

  displayTitle() {
    return this.props.name || `+${this.props.number}`;
  }

  unreadCount() {
    if (this.props.unread > 0) {
      return (
        <div className="float-right">
          <span className="badge badge-danger ml-2">{this.props.unread}</span>
        </div>
      );
    }

    return null;
  }

  classes() {
    const classes = ['nav-link'];

    if (this.props.active) {
      classes.push('active');
    }

    return classes.join(' ');
  }

  render() {
    return (
      <li className="nav-item">
        <a className={this.classes()} href={`#counterpart-${this.props.number}`} onClick={this.handleLinkClick}>
          {this.unreadCount()}
          {this.displayTitle()}
          <div className="text-muted">{moment(this.props.last_message).local().format('Do MMM, HH:mm')}</div>
        </a>
      </li>
    );
  }
}

ConversationItem.defaultProps = {
  unread: 0
};

ConversationItem.propTypes = {
  active: PropTypes.bool,
  number: PropTypes.string.isRequired,
  name: PropTypes.string,
  unread: PropTypes.number,
  last_message: PropTypes.string,
  onClick: PropTypes.func.isRequired
};
