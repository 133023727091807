import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { roundToNearestMinutes, addSeconds, isValid } from 'date-fns';
import { fromZonedTime, toZonedTime, format } from 'date-fns-tz';
import { formatToTimeZone } from 'date-fns-timezone';

export default class PrebookingShortcut extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();

    this.minTime = fromZonedTime(
      roundToNearestMinutes(addSeconds(new Date(), props.prebookingThresholdMin), { nearestTo: 5 }),
      props.timezone.identifier
    );
    this.maxTime = fromZonedTime(
      roundToNearestMinutes(addSeconds(new Date(), props.prebookingThresholdMax), { nearestTo: 5 }),
      props.timezone.identifier
    );

    this.handleTimeChange = this.handleTimeChange.bind(this);

    this.browserTimezone = format(new Date(), 'z');
    this.productTimezone = format(new Date(), 'z', { timeZone: props.timezone.identifier });
  }

  defaultState() {
    return {
      requestedTime: this.props.requestedTime
    };
  }

  handleTimeChange(timeCandidate) {
    if (!isValid(new Date(timeCandidate))) {
      return;
    }

    const timeRoundedToFiveMinuteInterval = roundToNearestMinutes(timeCandidate, { nearestTo: 5 });
    const timeCandidateInUtc = fromZonedTime(timeRoundedToFiveMinuteInterval, this.props.timezone.identifier);

    // Can be removed once https://github.com/Hacker0x01/react-datepicker/pull/1718 is merged and released
    if (timeCandidateInUtc < this.minTime) {
      this.state.requestedTime = this.minTime;
      this.props.handleUpdate(this.minTime, this.props.requestedTimeForWaypoint);
    } else if (timeCandidateInUtc > this.maxTime) {
      this.state.requestedTime = this.maxTime;
      this.props.handleUpdate(this.maxTime, this.props.requestedTimeForWaypoint);
    } else {
      this.state.requestedTime = timeCandidateInUtc;
      this.props.handleUpdate(timeCandidateInUtc, this.props.requestedTimeForWaypoint);
    }
  }

  renderTimezoneInfo() {
    if (this.browserTimezone === this.productTimezone) {
      return null;
    }

    const formattedTimezone = formatToTimeZone(new Date(), 'z ([UTC]Z)', {
      timeZone: this.props.timezone.identifier
    });

    return (
      <div className="input-group-append">
        <span className="input-group-text">{`${window.locales.BookingWizard.timezone}: ${formattedTimezone}`}</span>
      </div>
    );
  }

  render() {
    return (
      <div className="form-group">
        <div className="row">
          <div className="col-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <DatePicker
                  className="form-control input-xl"
                  showTimeSelect
                  timeIntervals={5}
                  timeFormat="p"
                  dateFormat="Pp"
                  onChange={this.handleTimeChange}
                  onFocus={(event) => event.target.select()}
                  selected={
                    this.state.requestedTime
                      ? toZonedTime(this.state.requestedTime, this.props.timezone.identifier)
                      : null
                  }
                  timeCaption={window.locales.BookingWizard.PickupTime.time}
                  maxDate={this.maxTime}
                  minDate={this.minTime}
                  disabled={this.props.disabled}
                />
                {this.renderTimezoneInfo()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrebookingShortcut.propTypes = {
  prebookingThresholdMin: PropTypes.number.isRequired,
  prebookingThresholdMax: PropTypes.number.isRequired,
  timezone: PropTypes.shape({
    identifier: PropTypes.string,
    utc_offset: PropTypes.number
  }),
  handleUpdate: PropTypes.func.isRequired,
  requestedTime: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool
};
