import $ from 'jquery';
import { setupRecurrenceRulesFormButtons } from '../../lib/form_helpers';

const AdministrationBlacklistedTravelCombinations = {
  init() {
    setupRecurrenceRulesFormButtons();
  }
}

export default AdministrationBlacklistedTravelCombinations;
