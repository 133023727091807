import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import loadData from '../../lib/bootstrapped_data';
import HereMap from '../../components/here_maps/HereMap';
import Station from '../../components/here_maps/markers/Station';
import Area from '../../components/here_maps/layers/Area';

const AdministrationStations = {
  init() {
    this.renderMaps();
  },

  renderArea() {
    const area = loadData('snap_area');
    if (!area) return null;

    return <Area area={area} />;
  },

  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;

    const station = loadData('station').data;

    ReactDOM.render(
      <HereMap autoZoom noTraffic center={{ lat: station.lat, lng: station.lng }}>
        {this.renderArea()}
        <Station station={station} />
      </HereMap>,
      mapEl.get(0)
    );
  }
}

export default AdministrationStations;
