import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import gmapsPreloader from '../../lib/gmaps_preloader';
import { addressComponentByType, determinePlaceName, addressInputValueFromWaypoint } from '../../lib/booking_helpers';

export default class AutocompleteAddressInput extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const options = {};
    const { boundingBox, waypoint } = this.props;

    if (waypoint) {
      this.inputRef.current.value = addressInputValueFromWaypoint(waypoint);
    }

    gmapsPreloader.ready(() => {
      if (boundingBox) {
        options.bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(boundingBox.min.lat, boundingBox.min.lng),
          new google.maps.LatLng(boundingBox.max.lat, boundingBox.max.lng)
        );
      }

      const search = new google.maps.places.Autocomplete(this.inputRef.current, options);

      search.addListener('place_changed', () => {
        const place = search.getPlace();
        if (!place) return;

        const placeName = determinePlaceName(place);

        const fullDisplayAddress = isEmpty(placeName)
          ? place.formatted_address
          : `${placeName}, ${place.formatted_address}`;

        this.props.handleUpdate({
          location_name: placeName,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          street_number: addressComponentByType(place, 'street_number'),
          street_name: addressComponentByType(place, 'route'),
          postal_code: addressComponentByType(place, 'postal_code'),
          city: addressComponentByType(place, 'locality'),
          county: addressComponentByType(place, 'administrative_area_level_1'),
          country: addressComponentByType(place, 'country'),
          formatted_full_address: fullDisplayAddress
        });

        this.inputRef.current.value = fullDisplayAddress;
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.waypoint, this.props.waypoint)) {
      this.inputRef.current.value = addressInputValueFromWaypoint(this.props.waypoint);
    }
  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label>
        <input ref={this.inputRef} className="form-control" data-autocomplete="address" />
      </div>
    );
  }
}

AutocompleteAddressInput.propTypes = {
  label: PropTypes.string,
  handleUpdate: PropTypes.func.isRequired,
  waypoint: PropTypes.object,
  boundingBox: PropTypes.object
};
