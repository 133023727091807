import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Tooltip } from 'react-leaflet';
import { DivIcon } from 'leaflet';
import stationSVG from '../../../images/maps/svg_icons/station.svg';

export default class Place extends React.Component {
  render() {
    const { lat, lng } = this.props.place;
    const color = '#000';

    const size = 18;

    const $svg = $(stationSVG);
    $svg.find('.colorize').css({ fill: color });

    const icon = new DivIcon({
      html: $svg.get(0),
      iconSize: [size, size],
      iconAnchor: [size / 2, size],
      className: 'place-marker'
    });

    return (
      <Marker position={[lat, lng]} icon={icon} zIndexOffset={800} type="station">
        <Tooltip direction="top" offset={[0, -size]}>
          <i className="fa fa-home mr-1" />
          {this.props.place.location_name}
        </Tooltip>
      </Marker>
    );
  }
}

Place.propTypes = {
  place: PropTypes.object.isRequired
};
