import $ from 'jquery';
import { extractCSRFTokenFromPage } from './generic_helpers';

const formatProgress = (event) => {
  if (!event.lengthComputable) return null;

  const max = event.total;
  const current = event.loaded;
  let pct = (current * 100) / max;

  if (pct >= 100) pct = 100;

  return pct;
};

const notify = (deferred, event) => {
  const progress = formatProgress(event);

  if (progress) {
    deferred.notify({ event, progress });
  }
};

const catchProgress = (deferred) => {
  const xhr = $.ajaxSettings.xhr();
  if (xhr.upload) {
    xhr.upload.addEventListener('progress', notify.bind(null, deferred), false);
  }
  return xhr;
};

const perform = (file) => {
  const defer = $.Deferred();
  const form = new window.FormData();
  form.append('image_upload[image]', file);

  $.post({
    url: '/admin/image_uploads',
    headers: {
      'X-CSRF-Token': extractCSRFTokenFromPage()
    },
    xhr: catchProgress.bind(null, defer),
    cache: false,
    contentType: false,
    processData: false,
    data: form
  })
    .then(defer.resolve)
    .catch(defer.reject);

  return defer;
};

export default { perform };
