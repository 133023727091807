import $ from 'jquery';

const AdministrationUniversalLinks = {
  init() {
    this.newUniversalLinkForm();
  },

  newUniversalLinkForm() {
    const universalLinkForm = $('form#new_universal_link');
    if (universalLinkForm.length === 0) return;

    const providerSelect = $('.js-provider-select');

    providerSelect.on('change', (e) => {
      $(e.target).parents('form').submit();
    });
  }
}

export default AdministrationUniversalLinks;
