import $ from 'jquery';
import moment from 'moment-timezone';

const settings = {
  lang: null
};

const setupI18n = () => {
  const lang = $('html').attr('lang');
  const { locales } = window;

  settings.lang = lang;
  // eslint-disable-next-line no-underscore-dangle
  $.timeago.settings.strings = locales._timeago;
  moment.locale(lang);
};

const getLocale = () => settings.lang;

const currencies = {
  EUR: { prefix: '', suffix: '€', decimalScale: 2, decimalSeparator: ',' },
  USD: { prefix: '$', suffix: '', decimalScale: 2, decimalSeparator: '.' },
  GBP: { prefix: '£', suffix: '', decimalScale: 2, decimalSeparator: ',' },
  CHF: { prefix: '', suffix: 'CHF', decimalScale: 2, decimalSeparator: ',' }
};

export default { setupI18n, getLocale, currencies };
