import React from 'react';
import PropTypes from 'prop-types';
import { generateComponentIdentifier } from '../../lib/booking_helpers';

export default class WaypointSwitcher extends React.Component {
  constructor(props) {
    super(props);

    this.identifier = generateComponentIdentifier();
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(event) {
    this.props.handleUpdate(event.currentTarget.value);
  }

  classForWaypoint(waypoint) {
    return waypoint === this.props.waypoint ? 'active' : '';
  }

  render() {
    return (
      <div className="btn-group btn-group-toggle">
        <label
          htmlFor={`origin-value-${this.identifier}`}
          className={`btn btn-secondary ${this.classForWaypoint('origin')}`}
        >
          <input
            type="radio"
            id={`origin-value-${this.identifier}`}
            name={`waypoint-${this.identifier}`}
            onChange={this.handleToggle}
            value="origin"
            disabled={this.props.disabled}
            checked={this.props.waypoint === 'origin'}
          />
          {this.props.originLabel || window.locales.BookingWizard.origin}
        </label>
        <label
          htmlFor={`destination-value-${this.identifier}`}
          className={`btn btn-secondary ${this.classForWaypoint('destination')}`}
        >
          <input
            type="radio"
            id={`destination-value-${this.identifier}`}
            name={`waypoint-${this.identifier}`}
            onChange={this.handleToggle}
            value="destination"
            disabled={this.props.disabled}
            checked={this.props.waypoint === 'destination'}
          />
          {this.props.destinationLabel || window.locales.BookingWizard.destination}
        </label>
      </div>
    );
  }
}

WaypointSwitcher.defaultProps = {
  disabled: false
};

WaypointSwitcher.propTypes = {
  waypoint: PropTypes.oneOf(['origin', 'destination']),
  handleUpdate: PropTypes.func.isRequired,
  originLabel: PropTypes.string,
  destinationLabel: PropTypes.string,
  disabled: PropTypes.bool
};
