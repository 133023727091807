import $ from 'jquery';

const AdministrationClients = {
  init() {
    this.newClientForm();
  },

  newClientForm() {
    const clientForm = $('form#new_client');
    if (clientForm.length === 0) return;

    const providerSelect = $('.js-provider-select');

    providerSelect.on('change', (e) => {
      $(e.target).parents('form').submit();
    });

    const platformSelect = $('.js-platform-select');

    platformSelect.on('change', (e) => {
      const selectedPlatform = $(e.target).val();

      $('.js-type-specific').hide();
      $(`.js-type-${selectedPlatform}`).show();
    });
  }
}

export default AdministrationClients;
