import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, FormGroup, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import { extractValidationErrors } from '../../lib/booking_helpers';
import PhoneNumberInput from '../PhoneNumberInput';
import { formattedShortDate } from '../../lib/view_helpers';

export default class NewUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState(props);
    this.updatePhoneNumber = this.updatePhoneNumber.bind(this);
    this.updateFirstName = this.updateStateForField.bind(this, 'firstName');
    this.updateLastName = this.updateStateForField.bind(this, 'lastName');
    this.updateEmail = this.updateStateForField.bind(this, 'emailAddress');
    this.updateTermsAccepted = this.updateTermsAccepted.bind(this);
    this.updateMinimumAgeConfirmed = this.updateMinimumAgeConfirmed.bind(this);
    this.updateUserSegment = this.updateUserSegment.bind(this);
    this.createUser = this.createUser.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose() {
    this.setState(this.defaultState(this.props));
  }

  defaultState(props) {
    const properties = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: '',
      termsAccepted: false,
      minimumAgeConfirmed: false,
      errorMessage: null,
      fieldErrors: {},
      userSegmentId: ''
    };

    if (props.provider.available_user_segments.length === 1) {
      properties.userSegmentId = props.provider.available_user_segments[0].id;
    }

    return properties;
  }

  updatePhoneNumber(number) {
    this.setState({ phoneNumber: number });
  }

  updateTermsAccepted(event) {
    this.setState({ termsAccepted: event.target.checked });
  }

  updateMinimumAgeConfirmed(event) {
    this.setState({ minimumAgeConfirmed: event.target.checked });
  }

  updateStateForField(field, event) {
    this.setState({ [field]: event.target.value });
  }

  updateUserSegment(userSegmentOption) {
    this.setState({ userSegmentId: userSegmentOption.value });
  }

  providerHasMultipleUserSegments() {
    return this.props.provider.available_user_segments.length > 1;
  }

  canCreateUser() {
    return (
      !isEmpty(this.state.firstName) &&
      !isEmpty(this.state.lastName) &&
      !isEmpty(this.state.phoneNumber) &&
      !isEmpty(this.state.userSegmentId) &&
      this.state.termsAccepted &&
      ((!!this.props.provider.features.minimum_age_confirmation && this.state.minimumAgeConfirmed) ||
        !this.props.provider.features.minimum_age_confirmation)
    );
  }

  createUser() {
    const userParams = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone_number: this.state.phoneNumber,
      email: { email_address: this.state.emailAddress },
      terms_accepted: this.state.termsAccepted ? 'yes' : '',
      minimum_age_confirmed: this.state.minimumAgeConfirmed ? 'yes' : '',
      user_segment_id: this.state.userSegmentId
    };

    this.props.stellwerkAPI
      .createUser(userParams)
      .done((userData) => {
        this.props.onUserCreate(userData.data);
      })
      .fail((error) => {
        const response = error.responseJSON;
        this.setState({
          errorMessage: response.api_errors[0].message,
          fieldErrors: {
            ...extractValidationErrors(response.validation_errors, 'User'),
            ...extractValidationErrors(response.validation_errors, 'Email')
          }
        });
      });
  }

  errorOn(fieldName) {
    return fieldName in this.state.fieldErrors;
  }

  messagesFor(fieldName) {
    return (this.state.fieldErrors[fieldName] || []).join(', ');
  }

  renderUserSegment() {
    if (!this.providerHasMultipleUserSegments()) {
      return null;
    }

    const tagOptions = this.props.provider.available_user_segments.map((tag) => ({ label: tag.slug, value: tag.id }));

    return (
      <FormGroup>
        <Label>{window.locales.BookingWizard.NewUserModal.userSegmentId}</Label>
        <Select
          onChange={this.updateUserSegment}
          options={tagOptions}
          placeholder={window.locales.BookingWizard.NewUserModal.userSegmentIdPlaceholder}
        />
      </FormGroup>
    );
  }

  renderMinimumAgeRequirement() {
    if (!this.props.provider.features.minimum_age_confirmation) {
      return null;
    }

    return (
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            onChange={this.updateMinimumAgeConfirmed}
            invalid={this.errorOn('minimum_age_required')}
          />{' '}
          {window.locales.BookingWizard.NewUserModal.miniumAge.replace(
            '%{age}',
            this.props.provider.features.minimum_age_confirmation.minimum_age
          )}
        </Label>
      </FormGroup>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.show} onClosed={this.handleModalClose} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>{window.locales.BookingWizard.NewUserModal.title}</ModalHeader>
        <ModalBody className="always-show-feedback">
          <FormGroup>
            <Label>{window.locales.BookingWizard.NewUserModal.firstName}</Label>
            <Input autoComplete="off" onChange={this.updateFirstName} invalid={this.errorOn('first_name')} />
            <FormFeedback invalid="true">{this.messagesFor('first_name')}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>{window.locales.BookingWizard.NewUserModal.lastName}</Label>
            <Input autoComplete="off" onChange={this.updateLastName} invalid={this.errorOn('last_name')} />
            <FormFeedback invalid="true">{this.messagesFor('last_name')}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>{window.locales.BookingWizard.NewUserModal.phoneNumber}</Label>
            <PhoneNumberInput
              onChange={this.updatePhoneNumber}
              invalid={this.errorOn('phone_number')}
              defaultCountry={this.props.provider.country_code}
            />
            <FormFeedback invalid="true">{this.messagesFor('phone_number')}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>{window.locales.BookingWizard.NewUserModal.emailAddress}</Label>
            <Input autoComplete="off" onChange={this.updateEmail} invalid={this.errorOn('email_address')} />
            <FormFeedback invalid="true">{this.messagesFor('email_address')}</FormFeedback>
          </FormGroup>

          {this.renderUserSegment()}

          {this.renderMinimumAgeRequirement()}

          <FormGroup check>
            <Label check>
              <Input type="checkbox" onChange={this.updateTermsAccepted} invalid={this.errorOn('terms_accepted')} />{' '}
              {window.locales.BookingWizard.NewUserModal.termsAccepted.replace(
                '%{date}',
                formattedShortDate(this.props.provider.current_tos_date)
              )}
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div className="text-danger">{this.state.errorMessage}</div>
          <Button color="primary" disabled={!this.canCreateUser()} onClick={this.createUser}>
            {window.locales.BookingWizard.buttons.createUser}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

NewUserModal.propTypes = {
  stellwerkAPI: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onUserCreate: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired
};
