import ReactDOM from 'react-dom';
import React from 'react';
import loadData from '../../lib/bootstrapped_data';
import ProductMap from '../../components/here_maps/ProductMap';

const AdministrationVehicles = {
  init() {
    this.renderMaps();
  },

  renderMaps() {
    const mapEl = window.document.getElementById('js-map-container');
    if (!mapEl) return;

    const lastPosition = loadData('last_position');
    const currentPosition = loadData('current_position');
    const vehicle = loadData('vehicle');
    const positions = loadData('positions');
    const drtArea = loadData('drt_area') !== undefined ? loadData('drt_area').data : undefined;
    const intermodalArea = loadData('intermodal_area') !== undefined ? loadData('intermodal_area').data : undefined;
    const boundingBox = loadData('bbox');

    ReactDOM.render(
      <ProductMap
        drtArea={drtArea}
        intermodalArea={intermodalArea}
        boundingBox={boundingBox}
        vehicleLastPositions={lastPosition && [lastPosition.data]}
        vehicleCurrentPositions={currentPosition && [currentPosition.data]}
        vehicles={[vehicle.data]}
        trail={positions.data}
      />,
      mapEl
    );
  }
}

export default AdministrationVehicles;
