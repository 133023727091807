import gmapsPreloader from '../lib/gmaps_preloader';

function preventFormSubmission($input) {
  $input.on('keydown', (e) => {
    if (e.keyCode === 13) e.preventDefault();
  });
}

function determinePlaceName(place) {
  if (place.types.includes('point_of_interest')) {
    return place.name;
  }
  return place.formatted_address;
}

function addressComponentByType(place, type) {
  const components = place.address_components;
  const filtered = components.filter((c) => c.types.includes(type));

  if (filtered.length === 0) return null;

  return filtered[0].long_name;
}

function parseResult(place) {
  return {
    location_name: determinePlaceName(place),
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
    street_number: addressComponentByType(place, 'street_number'),
    street_name: addressComponentByType(place, 'route'),
    postal_code: addressComponentByType(place, 'postal_code'),
    city: addressComponentByType(place, 'locality'),
    county: addressComponentByType(place, 'administrative_area_level_1'),
    country: addressComponentByType(place, 'country')
  };
}

function fillForm($container, place) {
  const parsed = parseResult(place);

  Object.entries(parsed).forEach(([key, val]) => {
    $container.find(`[data-attr="${key}"]`).val(val);
  });
}

function setupAutocompletion({ $container, $input, options }) {
  let bounds = null;

  if (options.bounds) {
    bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(options.bounds.min.lat, options.bounds.min.lng),
      new google.maps.LatLng(options.bounds.max.lat, options.bounds.max.lng)
    );
  }

  const searchBox = new google.maps.places.Autocomplete($input[0], { bounds });

  searchBox.addListener('place_changed', () => {
    const place = searchBox.getPlace();
    if (!place) return;

    fillForm($container, place);
  });
}

function init($container, options = {}) {
  const $input = $container.find('[data-autocomplete="address"]');
  preventFormSubmission($input);

  gmapsPreloader.ready(() => setupAutocompletion({ $container, $input, options }));
}

export default { init };
