import $ from 'jquery';
import 'bootstrap';

import queryString from 'query-string';
import ClipboardJS from 'clipboard';
import ReactDOM from 'react-dom';
import React from 'react';
import Rails from '@rails/ujs';
import 'timeago/jquery.timeago';
import { NumericFormat } from 'react-number-format';
// The utils need to be loaded before the intl-tel-input library is loaded.
import 'intl-tel-input/build/js/utils';
import intlTelInput from 'intl-tel-input';
import setupNavigationOverflow from '../lib/navigation_overflow';
import localization from '../utils/localization';
import ImageUploader from '../components/ImageUploader';
import autocompleteAddress from '../utils/autocomplete_address';
import loadData from '../lib/bootstrapped_data';
import { setupKeepOpenForDropdowns, setupSearchDropdowns } from '../lib/dropdown_filters';
import { setupTomSelect, setupTomSelectTagging, setupTomSelectAjax } from '../lib/form_helpers';
import StellwerkAPI from '../lib/stellwerk_api';

// import all controllers from the controllers directory
const controllers = import.meta.glob('../controllers/**/*.jsx', { eager: true });

// invoke controller-specific init scripts
const setupController = () => {
  const controllerName = window.document.body.dataset.ctrl;

  if (controllerName) {
    const controllerPath = `../controllers/${controllerName}.jsx`;
    const controllerModule = controllers[controllerPath];

    if (controllerModule) {
      controllerModule.default.init();
    }
  }
};

const setupClipboard = () => {
  $('[data-clipboard-text]').tooltip();

  const clipboard = new ClipboardJS('[data-clipboard-text]');

  clipboard.on('success', (e) => {
    const $el = $(e.trigger);
    const originalTitle = $el.data('title');

    $el.attr('title', 'Copied!').tooltip('_fixTitle').tooltip('show').attr('title', originalTitle).tooltip('_fixTitle');

    e.clearSelection();
  });
};

const setupTooltips = () => {
  $('[data-toggle="tooltip"]').tooltip();
};

const setupLeftSideBarToggle = () => {
  $('.left-sidebar-toggle').on('click', () => {
    const spacer = $(this).next('.left-sidebar-spacer');
    const toggleBtn = $(this);

    toggleBtn.toggleClass('open');
    spacer.slideToggle(500, () => {
      $(this).removeAttr('style').toggleClass('open');
    });
  });
};

const setupCollapse = () => {
  $('a.btn[data-action="toggle-collapse"]').on('click', (event) => {
    $(event.currentTarget).parent('.collapse-group').find('.collapse').collapse('toggle');
  });

  $('a.btn[data-action="toggle-collapse-table-row"]').on('click', (event) => {
    $(event.currentTarget).parents('.collapse-group').next('.collapse').collapse('toggle');
  });
};

const setupSelectTags = () => {
  Array.from(document.querySelectorAll('.js-tagging')).forEach(element => {
    setupTomSelectTagging(element);
  });

  Array.from(document.querySelectorAll('select:not([data-no-tomselect]):not(.js-autocomplete-user-widget):not(.js-autocomplete-station-widget):not(.js-tagging)')).forEach(element => {
    setupTomSelect(element);
  });
};

const setupAutocompletion = () => {
  $('time.timeago').timeago();

  document.querySelectorAll('.js-autocomplete-user-widget, .js-autocomplete-station-widget').forEach(element => {
    const url = element.dataset.selectAutocompletePath;

    setupTomSelectAjax(element, url);
  });

  $('.js-autocomplete-address-widget').each((i, el) => {
    const bbox = loadData('bounding_box');
    autocompleteAddress.init($(el), { bounds: bbox });
  });
};

const setupImageUploader = () => {
  $('.js-image-uploader-widget').each((i, el) => {
    const $el = $(el);
    const currentId = $el.data('current-id');
    const currentUrl = $el.data('current-url');
    const fieldName = $el.data('field-name');

    ReactDOM.render(<ImageUploader currentId={currentId} currentUrl={currentUrl} fieldName={fieldName} />, el);
  });
};

const setupLoadingIndicators = () => {
  $('input[type="submit"], button[type="submit"]').click(function handleButtonClick() {
    const $el = $(this);
    $el.width($el.width());
    $el.html('<i class="fa fa-spinner fa-pulse fa-fw"></i>');
  });
};

const setupPhoneNumberInputs = () => {
  document.querySelectorAll('input[type="tel"]').forEach((input) => {
    const fieldName = input.getAttribute('name');
    const initialCountry = input.dataset.countryCode || 'auto';

    input.setAttribute('name', `tel-input-for-${fieldName.replace(/\[|\]/g, '_')}`);

    intlTelInput(input, {
      hiddenInput: () => ({
        phone: fieldName,
        country: fieldName.endsWith("]") ? fieldName.replace(/\]$/, "_country]") : fieldName.concat("_country")
      }),
      initialCountry,
      autoHideDialCode: false,
      nationalMode: false,
      preferredCountries: ['de', 'gb', 'at', 'es', 'ch', 'nl', 'da', 'tr', 'uk']
    });
  });
};

const setupBootstrapTabFromURL = () => {
  const params = queryString.parse(window.location.search);
  const locationTab = $(`.nav-tabs a[href="#${params.tab}"]`);

  if ($('.nav-tabs').length > 0) {
    if (params.tab && locationTab.length > 0) {
      locationTab.tab('show');
    } else {
      $('.nav-tabs a:first').tab('show');
    }
  }

  $('.nav-tabs a').on('shown.bs.tab', (e) => {
    const currentParams = queryString.parse(window.location.search);
    currentParams.tab = e.target.hash.replace('#', '');
    const url = `${window.location.pathname}?${queryString.stringify(currentParams)}`;
    window.history.replaceState(null, null, url);
  });
};

const setupMoneyInputs = () => {
  $('.js-money-input').each((_, el) => {
    const $el = $(el);
    const currency = localization.currencies[$el.data('currency')];
    const exponent = 10 ** currency.decimalScale;
    const givenValue = $el.val() / exponent;

    const $newEl = $('<div>');
    $el.after($newEl);

    const syncValue = (values) => {
      const value = Math.round(values.floatValue * exponent);
      $el.attr('value', value);
    };

    ReactDOM.render(
      <NumericFormat
        {...currency}
        fixedDecimalScale
        onValueChange={syncValue}
        value={givenValue}
        isNumericString
        className="form-control js-rendered-money-input"
        disabled={$el.prop('disabled')}
      />,
      $newEl.get(0)
    );
  });
};

const setupSlugProposal = () => {
  const candidate = $('.js-slug-candidate');
  if (!candidate.length) return;

  candidate.on('blur', (e) => {
    const candidateInput = $(e.target);
    const slug = $('.js-slug');

    if (slug.val() !== '') return;

    const generatedSlug = `${candidateInput.val()}`.replace(/\W/gi, '').toLowerCase();
    slug.val(generatedSlug);
  });
};

const setupSecureInputs = () => {
  const secureInputEnableButtons = $('.js-toggle-secure-input');
  if (!secureInputEnableButtons.length) return;

  secureInputEnableButtons.on('change', (e) => {
    const checkbox = $(e.target);
    const matchingInput = checkbox.parents('.form-group').children(`[data-attr=${checkbox.data('attr')}]`);

    matchingInput.attr('disabled', !checkbox.is(':checked'));
    if (checkbox.is(':checked') && matchingInput.val() === '**********') {
      matchingInput.val('');
      matchingInput.focus();
    }

    if (!checkbox.is(':checked') && matchingInput.val() === '') {
      matchingInput.val('**********');
    }
  });
};

const setupLatLngAutofill = () => {
  const locationSelect = $('.js-autofill-lat-lng');
  if (!locationSelect.length) return;

  const locationNameInput = locationSelect.parents('form').find(`[data-attr='location_name']`);
  const streetNameInput = locationSelect.parents('form').find(`[data-attr='street_name']`);
  const streetNumberInput = locationSelect.parents('form').find(`[data-attr='street_number']`);
  const postalCodeInput = locationSelect.parents('form').find(`[data-attr='postal_code']`);
  const cityInput = locationSelect.parents('form').find(`[data-attr='city']`);
  const countyInput = locationSelect.parents('form').find(`[data-attr='county']`);
  const countryInput = locationSelect.parents('form').find(`[data-attr='country']`);
  const latInput = locationSelect.parents('form').find(`[data-attr='lat']`);
  const lngInput = locationSelect.parents('form').find(`[data-attr='lng']`);

  locationSelect.on('change', () => {
    const selectedOption = locationSelect.find(':selected');

    locationNameInput.val(selectedOption.data('location-name'));
    streetNameInput.val(selectedOption.data('street-name'));
    streetNumberInput.val(selectedOption.data('street-number'));
    postalCodeInput.val(selectedOption.data('postal-code'));
    cityInput.val(selectedOption.data('city'));
    countyInput.val(selectedOption.data('county'));
    countryInput.val(selectedOption.data('country'));
    latInput.val(selectedOption.data('lat'));
    lngInput.val(selectedOption.data('lng'));
  });
};

const setupAppSuite = () => {
  const appSwitcher = $('.js-app-switcher');
  if (!appSwitcher.length) return;

  const globalContext = $('body');
  if (!globalContext.length) return;

  const appSwitcherBtn = appSwitcher.find('.js-app-switcher-btn');
  if (!appSwitcherBtn.length) return;

  const appSwitcherPane = appSwitcher.find('.js-app-switcher-pane');
  if (!appSwitcherPane.length) return;

  $(appSwitcherBtn).on('click', (event) => {
    event.stopPropagation();
    event.preventDefault();
    appSwitcherPane.toggle();
    appSwitcher.toggleClass('active');
  });

  $(globalContext).on('click', () => {
    appSwitcherPane.hide();
    appSwitcher.removeClass('active');
  });
};

const setupLanguageSwitcher = () => {
  const languageSwitcher = $('.js-language-switcher');
  if (!languageSwitcher.length) return;

  const languageSwitcherSelect = languageSwitcher.find('select');
  if (!languageSwitcherSelect.length) return;

  $(languageSwitcherSelect).on('change', (event) => {
    event.stopPropagation();
    event.preventDefault();

    const selectedLocale = $(event.target).val();
    const updateData = { locale: selectedLocale };

    const api = new StellwerkAPI(null);
    api.updateAccountSettings(updateData).done(() => {
      window.location.reload();
    });
  });
};

const isOperatorWindow = () => {
  try {
    return String(window.top.location).includes("operator")
  } catch(_e) { // eslint-disable-line no-unused-vars
    return true
  }
};

const reenableNavigation = () => {
  const nonOperatorWindow = !isOperatorWindow();
  const navbarHidden = ($('.be-navbar-header').length === 0) && ($('.devise-main-wrapper').length === 0);
  const hasQueryParams = (String(window.location).includes("?"));
  const urlContainsDisableNavigation = (String(window.location).includes("disable_navigation"));

  if (nonOperatorWindow && navbarHidden && !urlContainsDisableNavigation) {
    if (hasQueryParams) {
      window.location += '&disable_navigation=false';
    } else {
      window.location += '?disable_navigation=false';
    }
  }
};

$(() => {
  Rails.start();

  localization.setupI18n();
  setupController();
  setupClipboard();
  setupCollapse();
  setupTooltips();
  setupAutocompletion();
  setupSelectTags();
  setupImageUploader();
  setupLoadingIndicators();
  setupPhoneNumberInputs();
  setupBootstrapTabFromURL();
  setupLeftSideBarToggle();
  setupMoneyInputs();
  setupNavigationOverflow();
  setupSlugProposal();
  setupKeepOpenForDropdowns();
  setupSearchDropdowns();
  setupSecureInputs();
  setupLatLngAutofill();
  setupAppSuite();
  setupLanguageSwitcher();
  reenableNavigation();
});
