import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import loadData from '../../lib/bootstrapped_data';
import HereMap from '../../components/here_maps/HereMap';
import Place from '../../components/here_maps/markers/Place';

const AdministrationPlaces = {
  init() {
    this.renderMaps();
  },

  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;

    const place = loadData('place').data;

    ReactDOM.render(
      <HereMap autoZoom noTraffic boundingBox={loadData('bbox')}>
        <Place place={place} />
      </HereMap>,
      mapEl.get(0)
    );
  }
}

export default AdministrationPlaces;
