import React from 'react';
import PropTypes from 'prop-types';

export default class PassengerNoteToDriver extends React.Component {
  constructor(props) {
    super(props);

    this.handlePassengerNoteToDriverChange = this.handlePassengerNoteToDriverChange.bind(this);
  }

  handlePassengerNoteToDriverChange(event) {
    const note = event.target.value;
    this.props.handleUpdate(note);
  }

  render() {
    return (
      <div className="form-group mt-3">
        <label htmlFor="passengerNoteToDriver" className="col-form-label">
          {window.locales.BookingWizard.passengerNoteToDriver}
        </label>
        <textarea
          id="passengerNoteToDriver"
          value={this.props.note}
          onChange={this.handlePassengerNoteToDriverChange}
          className="form-control"
        />
      </div>
    );
  }
}

PassengerNoteToDriver.propTypes = {
  note: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired
};
