import React from 'react';
import PropTypes from 'prop-types';

import { AttributionControl, Map, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-control';
import { Polyline, Point, LatLngBounds, Marker as LeafletMarker, GeoJSON } from 'leaflet';
import HereTrafficLayer from './layers/HereTrafficLayer';
import { pointOnCircle } from '../../lib/map_helpers';

const markersIgnoredFromClustring = ['station', 'vehicleBreadcrumb'];

export default class HereMap extends React.Component {
  constructor(props) {
    super(props);

    this.hereApiKey = import.meta.env.VITE_HERE_API_KEY_STELLWERK_MAPS;
    this.baseLayerURL = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?apiKey=${this.hereApiKey}`;
    this.helperPolylines = [];
    this.disableAutomaticMapHandling = this.disableAutomaticMapHandling.bind(this);
    this.enableAutomaticMapHandling = this.enableAutomaticMapHandling.bind(this);

    this.state = {
      automaticMapHandling: props.autoZoom
    };
  }

  componentDidMount() {
    if (this.props.boundingBox) {
      this.mapElement.leafletElement.fitBounds([this.props.boundingBox.min, this.props.boundingBox.max]);
    } else if (this.props.center) {
      this.mapElement.leafletElement.setView(this.props.center, 14);
    } else {
      this.mapElement.leafletElement.setView({ lat: 50, lng: 8 }, 8);
    }

    // when the map is zoomed by the zoom control buttons, disable map handling
    const zoomControls = document.querySelectorAll('.leaflet-control-zoom > a[role=button]');
    zoomControls.forEach((control) => {
      control.addEventListener('click', () => {
        this.disableAutomaticMapHandling();
      });
    });
  }

  componentDidUpdate() {
    const leafletMap = this.mapElement.leafletElement;
    const bounds = new LatLngBounds([]);
    const clusters = {};

    leafletMap.eachLayer((layer) => {
      if (layer instanceof LeafletMarker) {
        const latLng = layer.getLatLng();

        if (!markersIgnoredFromClustring.includes(layer.options.type)) {
          if (!clusters[latLng]) {
            clusters[latLng] = [];
          }
          clusters[latLng].push(layer);
        }

        bounds.extend(latLng);
      } else if (layer instanceof GeoJSON) {
        bounds.extend(layer.getBounds());
      }
    });

    this.unclusterify(clusters);

    if (this.props.autoZoom && this.state.automaticMapHandling) {
      if (this.props.animated) {
        leafletMap.flyToBounds(bounds);
      } else {
        leafletMap.fitBounds(bounds);
      }
    }
  }

  unclusterify(clusters) {
    const leafletMap = this.mapElement.leafletElement;

    this.helperPolylines.forEach((polyline) => {
      polyline.removeFrom(leafletMap);
    });

    this.helperPolylines = [];

    Object.keys(clusters).forEach((positionId) => {
      if (clusters[positionId].length === 1) {
        return;
      }

      const markers = clusters[positionId];
      const position = markers[0].getLatLng();
      const markerPoint = leafletMap.latLngToContainerPoint(position);

      for (let i = 0; i < markers.length; i++) {
        const marker = markers[i];
        const newPoint = new Point(
          ...pointOnCircle(markerPoint.x, markerPoint.y, 50 - leafletMap.getZoom(), markers.length, i)
        );

        const newMarkerLocation = leafletMap.containerPointToLatLng(newPoint);

        const helperLine = new Polyline([position, newMarkerLocation], {
          color: 'black',
          dashArray: '2,2',
          weight: 1
        }).addTo(leafletMap);

        this.helperPolylines.push(helperLine);
        marker.setLatLng(newMarkerLocation);
      }
    });
  }

  disableAutomaticMapHandling() {
    this.setState({ automaticMapHandling: false });
  }

  enableAutomaticMapHandling() {
    this.setState({ automaticMapHandling: true });
  }

  renderResetAutoZoomButton() {
    if (!this.props.autoZoom) {
      return null;
    }
    if (this.state.automaticMapHandling) {
      return null;
    }

    return (
      <Control position="topright">
        <button type="button" className="btn btn-light" onClick={this.enableAutomaticMapHandling}>
          {window.locales.HereMap.resetAutoZoom}
        </button>
      </Control>
    );
  }

  renderHereTrafficLayer() {
    if (this.props.noTraffic) {
      return null;
    }

    return <HereTrafficLayer />;
  }

  render() {
    return (
      <Map
        ref={(c) => {
          this.mapElement = c;
        }}
        animated={this.props.animated}
        scrollWheelZoom={!this.state.automaticMapHandling}
        attributionControl={false}
        onmousedown={this.disableAutomaticMapHandling}
      >
        <TileLayer url={this.baseLayerURL} attribution={`&copy; ${new Date().getFullYear()} HERE.com`} />
        <AttributionControl />
        {this.renderHereTrafficLayer()}
        {this.props.children}
        {this.renderResetAutoZoomButton()}
      </Map>
    );
  }
}

HereMap.propTypes = {
  autoZoom: PropTypes.bool,
  noTraffic: PropTypes.bool,
  children: PropTypes.node,
  boundingBox: PropTypes.shape({
    min: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    max: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })
  }),
  center: PropTypes.object,
  animated: PropTypes.bool
};

HereMap.defaultProps = {
  autoZoom: false,
  noTraffic: false,
  animated: true
};
