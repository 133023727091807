const availableColorPalette = [
  '#FF6633',
  '#00B4FF',
  '#AAAAAA',
  '#0000FF',
  '#00FF00',
  '#FF0000',
  '#8A9B0F',
  '#E08E79',
  '#774F38',
  '#B4A3CF',
  '#C7F464',
  '#0B486B',
  '#53777A',
  '#FA6900',
  '#69D2E7',
  '#83AF9B',
  '#8B628A',
  '#FE4365',
  '#5D9CEC',
  '#4FC1E9',
  '#48CFAD',
  '#A0D468',
  '#FFCE54',
  '#FC6E51',
  '#ED5565',
  '#AC92EC',
  '#EC87C0',
  '#FFAA00',
  '#FF00FF',
  '#800080',
  '#FF3385',
  '#4BAB07',
  '#3F0FDB'
];

const cachedColorsByLineId = {};
const cachedColorsByVehicleId = {};

const popYaColor = () => {
  const newColor = availableColorPalette.pop();
  if (!newColor) return '#000000'
  return newColor;
};

const colorForLine = (line) => {
  const cached = cachedColorsByLineId[line.id];
  if (cached) return cached;

  const color = popYaColor();
  cachedColorsByLineId[line.id] = color;
  return color;
};

const colorForVehicle = (vehicle) => {
  const cached = cachedColorsByVehicleId[vehicle.id];
  if (cached) return cached;

  const color = popYaColor();
  cachedColorsByVehicleId[vehicle.id] = color;
  return color;
};

export default { colorForLine, colorForVehicle };
