import React from 'react';
import PropTypes from 'prop-types';

import { Polyline, Marker as LeafletMarker } from 'react-leaflet';
import { LatLngBounds, Icon } from 'leaflet';
import HereMap from './HereMap';

import { itemSVG } from '../../lib/map_helpers';
import Area from './layers/Area';

const color = '#222222';

export default class RoutingTestsMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waypoints: props.routingTest.waypoints,
      route: props.routingTest.route,
      boundingBox: props.boundingBox
    };
  }

  componentDidMount() {
    // Hack to zoom into route without actually triggering autoZoom.
    if (this.state.waypoints.length > 1) {
      const bounds = new LatLngBounds(this.state.waypoints);

      this.setState({ boundingBox: bounds });
    }
  }

  renderWaypointMarkers() {
    return this.state.waypoints.map((waypoint, index) => {
      const icon = new Icon({
        iconUrl: itemSVG(color, index + 1),

        iconSize: [40, 40],
        iconAnchor: [20, 20]
      });

      return (
        <LeafletMarker
          key={`waypoint-marker-${index}`}
          position={waypoint}
          icon={icon}
          zIndexOffset={800}
          type="waypoint"
        />
      );
    });
  }

  renderRoute() {
    if (this.state.route.length === 0) {
      return null;
    }

    return <Polyline color={color} positions={this.state.route} />;
  }

  renderRestrictedAreas() {
    return this.props.restrictedAreas.map((restrictedArea) => (
      <Area
        key={restrictedArea.id}
        area={restrictedArea.bounding_box}
        color="#ff2b79"
        opacity={0.8}
        fillColor="#ff2b79"
      />
    ));
  }

  render() {
    return (
      <HereMap boundingBox={this.state.boundingBox} autoZoom noTraffic>
        {this.renderRestrictedAreas()}
        {this.renderWaypointMarkers()}
        {this.renderRoute()}
      </HereMap>
    );
  }
}

RoutingTestsMap.propTypes = {
  boundingBox: PropTypes.object.isRequired,
  routingTest: PropTypes.object.isRequired,
  restrictedAreas: PropTypes.array
};
