import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formattedTimeWithTimeZone, newlineStringToHTML } from '../../lib/view_helpers';

export default class OutgoingMessage extends Component {
  render() {
    const { timezone, message } = this.props;
    const i18n = window.locales.sms.OutgoingMessage;

    /* eslint-disable react/no-danger */
    return (
      <li className="self">
        <div className="msg" dangerouslySetInnerHTML={{ __html: newlineStringToHTML(message.content) }} />
        <div className="text-muted">
          {i18n.sentMessage}
          {' '}
          {this.props.message.admin_name}
          {' '}
          {i18n.sentAt}
          {' '}
          {formattedTimeWithTimeZone(message.created_at, timezone.name)}

        </div>
      </li>
    );
    /* eslint-enable react/no-danger */
  }
}

OutgoingMessage.propTypes = {
  message: PropTypes.object.isRequired,
  timezone: PropTypes.object.isRequired
};
