import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import Alert from '../../components/bootstrap/Alert';

const AdministrationFailedPayments = {
  init() {
    this.makeTableCellsClickable();

    $(document).on('failedPayments:success', this.handleSuccessEvent);
    $(document).on('failedPayments:failure', this.handleErrorEvent);
  },

  makeTableCellsClickable() {
    $('.js-radio-cell').on('click', (e) => {
      $(e.target).children('input[type="radio"]').prop('checked', true);
    });
  },

  handleSuccessEvent(_event, userId, rides) {
    ReactDOM.unmountComponentAtNode($(`.errors[data-user-id=${userId}]`).get(0));

    rides.forEach((rideId) => {
      const $row = $(`tr[data-user-id=${userId}][data-ride-id=${rideId}]`);
      $row.children('td.js-radio-cell').remove();
      $row.append('<td class="text-center table-info icon-cell" colspan="3"><i class="fa fa-fw fa-check"></i></td>');
    });
  },

  handleErrorEvent(_event, userId, errors) {
    const $errorBox = $(`.errors[data-user-id=${userId}]`);
    ReactDOM.unmountComponentAtNode($(`.errors[data-user-id=${userId}]`).get(0));

    ReactDOM.render(
      <>
        {errors.map((errorMessage) => (
          <Alert type="danger">{errorMessage}</Alert>
        ))}
      </>,
      $errorBox.get(0)
    );
  }
}

export default AdministrationFailedPayments;
