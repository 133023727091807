import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NewMessageInput extends Component {
  constructor(props) {
    super(props);

    this.clickSubmit = this.clickSubmit.bind(this);
    this.pressInputKey = this.pressInputKey.bind(this);
  }

  clickSubmit() {
    this.sendMessage();
  }

  pressInputKey(e) {
    if (e.ctrlKey && e.charCode === 13) {
      this.sendMessage();
      e.preventDefault();
    }
  }

  sendMessage() {
    const message = this.inputReference.value;
    this.props.onSubmit(message);
    this.inputReference.value = '';
  }

  renderMarkAsReadButton() {
    if (this.props.unreads) {
      return <i className="fa fa-check-circle-o text-success" onClick={this.props.markAsRead} />;
    }
    return null;
  }

  render() {
    const i18n = window.locales.sms.NewMessageInput;
    return (
      <div className="chat-input">
        <div className="input-wrapper">
          <textarea
            autoComplete="off"
            onKeyPress={this.pressInputKey}
            ref={(component) => {
              this.inputReference = component;
            }}
            placeholder={i18n.placeholder}
          />
          <div className="buttons">
            <i className="fa fa-paper-plane" onClick={this.clickSubmit} />
            {this.renderMarkAsReadButton()}
          </div>
        </div>
      </div>
    );
  }
}

NewMessageInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  unreads: PropTypes.bool
};
