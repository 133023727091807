import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteAddressInput from './AutocompleteAddressInput';
import StationDropdown from './StationDropdown';
import BooleanSwitch from './BooleanSwitch';

export default class WaypointSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleNearbyMatchingModeUpdate = this.handleNearbyMatchingModeUpdate.bind(this);
  }

  handleNearbyMatchingModeUpdate(value) {
    this.props.handleNearbyMatchingModeUpdate(this.props.waypointType, value);
  }

  renderFixedStationSelect() {
    return (
      <>
        <StationDropdown
          stations={this.props.fixedStations}
          handleUpdate={this.props.handleUpdate}
          waypoint={this.props.waypoint}
        />
        <BooleanSwitch
          value={this.props.nearbyMatchingMode}
          enforceSelection
          handleUpdate={this.handleNearbyMatchingModeUpdate}
          trueTitle={window.locales.BookingWizard.StationDropdown.nearbyMatchingMode.true}
          falseTitle={window.locales.BookingWizard.StationDropdown.nearbyMatchingMode.false}
        />
      </>
    );
  }

  renderStationOrPreviousPointDropdown() {
    if (this.props.stations.length === 0 && this.props.previouslyRequestedPoints.length === 0) {
      return null;
    }

    const displayedLabel =
      this.props.previouslyRequestedPoints.length > 0
        ? window.locales.BookingWizard.stationOrPreviousPoint
        : window.locales.BookingWizard.station;

    return (
      <>
        <StationDropdown
          label={displayedLabel}
          stations={this.props.stations}
          handleUpdate={this.props.handleUpdate}
          waypoint={this.props.waypoint}
          previouslyRequestedPoints={this.props.previouslyRequestedPoints}
        />
        <BooleanSwitch
          value={this.props.nearbyMatchingMode}
          handleUpdate={this.handleNearbyMatchingModeUpdate}
          trueTitle={window.locales.BookingWizard.StationDropdown.nearbyMatchingMode.true}
          falseTitle={window.locales.BookingWizard.StationDropdown.nearbyMatchingMode.false}
        />
      </>
    );
  }

  renderFreeStationSelect() {
    return (
      <>
        <AutocompleteAddressInput
          handleUpdate={this.props.handleUpdate}
          waypoint={this.props.waypoint}
          boundingBox={this.props.boundingBox}
        />
        {this.renderStationOrPreviousPointDropdown()}
      </>
    );
  }

  render() {
    const { productRequiresFixedStation, fixedWaypoint, waypointType } = this.props;
    if (productRequiresFixedStation && fixedWaypoint === waypointType) {
      return this.renderFixedStationSelect();
    }

    return this.renderFreeStationSelect();
  }
}

WaypointSelector.propTypes = {
  waypointType: PropTypes.oneOf(['origin', 'destination']).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleNearbyMatchingModeUpdate: PropTypes.func.isRequired,
  stations: PropTypes.array,
  fixedStations: PropTypes.array,
  waypoint: PropTypes.object,
  fixedWaypoint: PropTypes.oneOf(['origin', 'destination']),
  productRequiresFixedStation: PropTypes.bool,
  boundingBox: PropTypes.object,
  previouslyRequestedPoints: PropTypes.array,
  nearbyMatchingMode: PropTypes.bool
};
