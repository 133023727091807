import { escape, isString, isEmpty } from 'lodash';
import { parseISO, differenceInSeconds } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';
import React from 'react';

const secondsToHuman = (inputSeconds) => {
  const minutes = Math.floor(inputSeconds / 60);
  const seconds = inputSeconds % 60;

  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const secondsToHumanWithIndicator = (inputSeconds) => {
  if (inputSeconds < 0) return `- ${secondsToHuman(Math.abs(inputSeconds))}`;
  return `+ ${secondsToHuman(inputSeconds)}`;
};

const formattedShortDate = (time, timezone) => {
  const formattingTime = isString(time) ? parseISO(time) : time;
  return format(toZonedTime(formattingTime, timezone), 'P', { timeZone: timezone, locale: window.locales.dateFns });
};

const formattedShortTime = (time, timezone) => {
  const formattingTime = isString(time) ? parseISO(time) : time;
  return format(toZonedTime(formattingTime, timezone), 'p', { timeZone: timezone, locale: window.locales.dateFns });
};

const formattedTime = (time, timezone) => {
  const formattingTime = isString(time) ? parseISO(time) : time;
  return format(toZonedTime(formattingTime, timezone), 'PPpp', {
    timeZone: timezone,
    locale: window.locales.dateFns
  });
};

const formattedTimeWithTimeZone = (time, timezone) => {
  const formattingTime = isString(time) ? parseISO(time) : time;
  return format(toZonedTime(formattingTime, timezone), 'PPpp zzz',{
    timeZone: timezone,
    locale: window.locales.dateFns
  });
};

const timeDiff = (fromTime, toTime) => {
  if (!fromTime || !toTime) {
    return undefined;
  }

  const fromTimeAsDate = isString(fromTime) ? parseISO(fromTime) : fromTime;
  const toTimeAsDate = isString(toTime) ? parseISO(toTime) : toTime;

  return Math.abs(differenceInSeconds(fromTimeAsDate, toTimeAsDate));
};

const newlineStringToHTML = (inputString) => {
  if (typeof inputString === 'undefined' || inputString === null) {
    return '';
  }

  const breakTag = '<br />';
  return `${escape(inputString)}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

const pointForDisplay = (point) => {
  if (point.formatted_full_address) {
    return point.formatted_full_address;
  }
  if (point.station_id) {
    return (
      <>
        <i className="fa fa-dot-circle-o" />
        {point.location_name}
      </>
    );
  }
  if (!isEmpty(point.location_name)) {
    return point.location_name;
  }
  if (!isEmpty(point.formatted_street)) {
    return `${point.formatted_street}, ${point.city}`;
  }
  if (!isEmpty(point.city)) {
    return point.city;
  }
  return `${point.lat.toLocaleString()}, ${point.lng.toLocaleString()}`;
};

const pointForDisplayAsString = (point) => {
  if (point.formatted_full_address) {
    return point.formatted_full_address;
  }
  if (point.station_id) {
    return point.location_name;
  }
  if (!isEmpty(point.location_name)) {
    return point.location_name;
  }
  if (!isEmpty(point.formatted_street)) {
    return `${point.formatted_street}, ${point.city}`;
  }
  if (!isEmpty(point.city)) {
    return point.city;
  }
  return `${point.lat.toLocaleString()}, ${point.lng.toLocaleString()}`;
};

export {
  newlineStringToHTML,
  secondsToHuman,
  secondsToHumanWithIndicator,
  formattedTime,
  formattedTimeWithTimeZone,
  formattedShortTime,
  timeDiff,
  formattedShortDate,
  pointForDisplay,
  pointForDisplayAsString
};
