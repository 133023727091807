import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import LiveProductMapContainer from '../../components/LiveProductMapContainer';
import BookingWizard from '../../components/BookingWizard';
import loadData from '../../lib/bootstrapped_data';
import VisibilityManager from '../../lib/VisibilityManager';
import { setupTomSelect } from '../../lib/form_helpers';

const AdministrationRides = {
  init() {
    this.renderMaps();
    this.renderWizard();
    this.taskListSelectButtons();
    this.handleDateTypeChange();

    Array.from(document.querySelectorAll('.cancellation-statement')).forEach(element => {
      setupTomSelect(element);
    });
  },

  renderWizard() {
    const wizardEl = $('#js-booking-wizard');
    if (!wizardEl.length) return;

    const product = loadData('product').data;
    const stations = loadData('stations').data;
    const areas = loadData('areas').data;
    const ride = loadData('cloning_ride');

    let cloningRide = {};
    let cloningDirection;

    if (ride && ride.data) {
      cloningRide = ride.data;
      cloningDirection = loadData('cloning_direction');
    }

    ReactDOM.render(
      <BookingWizard
        product={product}
        stations={stations}
        areas={areas}
        cloningRide={cloningRide}
        cloningDirection={cloningDirection}
      />,
      wizardEl.get(0)
    );
  },

  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;
    ReactDOM.render(
        <VisibilityManager>
          {isVisible => <LiveProductMapContainer liveDataUrl={mapEl.data('live')} isVisible={isVisible} />}
        </VisibilityManager>, mapEl.get(0));
  },

  taskListSelectButtons() {
    const checkAllButton = $('.js-check-all');
    const unCheckAllButton = $('.js-uncheck-all');
    if (!checkAllButton.length && !unCheckAllButton.length) return;

    const selectBoxes = $('.js-select-tasklist:enabled');

    checkAllButton.on('click', () => {
      selectBoxes.prop('checked', true);
    });

    unCheckAllButton.on('click', () => {
      selectBoxes.prop('checked', false);
    });
  },

  handleDateTypeChange() {
    $('.js-radio-cancellation').on('click', (e) => {
      if (e.target.value === '1') {
        $('.cancellation-statement').prop('disabled', true);
        $('.reported-cancelled-at').prop('disabled', true);
      } else {
        $('.cancellation-statement').prop('disabled', false);
        $('.reported-cancelled-at').prop('disabled', false);
      }
    });
  }
}

export default AdministrationRides;
