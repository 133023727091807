import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formattedTimeWithTimeZone, newlineStringToHTML } from '../../lib/view_helpers';

export default class IncomingMessage extends Component {
  messageMetadata() {
    const { message, timezone } = this.props;
    const i18n = window.locales.sms.IncomingMessage;

    let metadataMessage = `${i18n.receivedMessage} ${formattedTimeWithTimeZone(message.created_at, timezone.name)}`;
    if (message.read_at) {
      metadataMessage = `${metadataMessage}, ${i18n.readMessage} ${message.admin_name} ${i18n.readAt} ${formattedTimeWithTimeZone(
        message.read_at
      )}`;
    }

    return metadataMessage;
  }

  render() {
    const { message } = this.props;

    /* eslint-disable react/no-danger */
    return (
      <li className="friend">
        <div className="msg" dangerouslySetInnerHTML={{ __html: newlineStringToHTML(message.content) }} />
        <div className="text-muted">{this.messageMetadata()}</div>
      </li>
    );
    /* eslint-enable react/no-danger */
  }
}

IncomingMessage.propTypes = {
  message: PropTypes.object.isRequired,
  timezone: PropTypes.object.isRequired
};
