import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { debounce, isEmpty, isNil } from 'lodash';
import { buildSelectOptionFromUser } from '../../lib/booking_helpers';
import NewUserModal from './NewUserModal';

const waitBeforeSending = 500; // milliseconds

export default class UserSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      options: []
    };

    this.handleUserUpdate = this.handleUserUpdate.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.debouncedLoadData = debounce(this.loadData.bind(this), waitBeforeSending);
    this.userWasCreated = this.userWasCreated.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ options: buildSelectOptionFromUser(this.props.existingUser) });
  }

  handleUserUpdate(userOption) {
    this.props.handleUpdate(userOption.value);
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  userWasCreated(user) {
      this.setState({ options: buildSelectOptionFromUser(user), showModal: false });
      this.props.handleUpdate(user.id);
    }

  loadData(searchTerm, callback) {
    this.props.stellwerkAPI
      .autoCompleteUser(searchTerm)
      .done((result) => {
        this.setState({ options: result.data });
        callback(result.data);
      })
      .fail((data) => {
        console.log(data);
      });
  }

  selectedValue() {
    if (isNil(this.props.selectedUserId)) return null;

    return this.state.options.find((o) => o.value === this.props.selectedUserId);
  }

  noOptionsMessage({ inputValue }) {
    if (isEmpty(inputValue)) {
      return window.locales.BookingWizard.userPlaceholder;
    }

    return window.locales.BookingWizard.noUsersFound;
  }

  render() {
    return (
      <>
        <div className="form-group">
          <label htmlFor="user-id" className="col-form-label">
            {window.locales.BookingWizard.user}
          </label>
          <div className="row">
            <AsyncSelect
              name="user-id"
              options={this.state.options}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              value={this.selectedValue()}
              loadOptions={this.debouncedLoadData}
              onChange={this.handleUserUpdate}
              placeholder={window.locales.BookingWizard.userPlaceholder}
              noOptionsMessage={this.noOptionsMessage}
              loadingMessage={() => window.locales.BookingWizard.lookingForUsers}
              className="col-10 react-select-container"
              classNamePrefix="react-select"
              isOptionDisabled={(option) => option.locked}
            />
            <button className="col-2 btn btn-primary" onClick={this.toggleModal} type="button">
              {window.locales.BookingWizard.buttons.newUser}
            </button>
          </div>
        </div>
        <NewUserModal
          stellwerkAPI={this.props.stellwerkAPI}
          show={this.state.showModal}
          toggle={this.toggleModal}
          onUserCreate={this.userWasCreated}
          provider={this.props.provider}
        />
      </>
    );
  }
}

UserSelect.propTypes = {
  handleUpdate: PropTypes.func.isRequired,
  existingUser: PropTypes.object,
  stellwerkAPI: PropTypes.object.isRequired,
  selectedUserId: PropTypes.string,
  provider: PropTypes.object.isRequired
};
