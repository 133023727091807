import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default class PaymentMethodDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.handlePaymentMethodUpdate = this.handlePaymentMethodUpdate.bind(this);
  }

  getValue(paymentMethod) {
    return paymentMethod.payment_method_type === 'stripe' ? paymentMethod.id : paymentMethod.payment_method_type;
  }

  handlePaymentMethodUpdate(selectedPaymentMethod) {
    this.props.handleUpdate(selectedPaymentMethod);
  }

  render() {
    const value = this.props.chosenPaymentMethod ? this.getValue(this.props.chosenPaymentMethod) : null;
    const localizations = window.locales.BookingWizard.PaymentMethodDropdown;

    return (
      <div className="form-group">
        <label htmlFor="payment-method" className="col-form-label">
          {this.props.label}
        </label>
        <Select
          name="payment-method"
          options={this.props.paymentMethods}
          getOptionValue={(pm) => this.getValue(pm)}
          getOptionLabel={(pm) => pm.details || localizations[pm.payment_method_type]}
          value={this.props.paymentMethods.find((pm) => this.getValue(pm) === value)}
          isSearchable
          isDisabled={this.props.isDisabled}
          onChange={this.handlePaymentMethodUpdate}
          placeholder={this.props.isDisabled ? localizations.placeholderWhenDisabled : localizations.placeholder}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
    );
  }
}

PaymentMethodDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  chosenPaymentMethod: PropTypes.shape({
    payment_method_type: PropTypes.string.isRequired,
    details: PropTypes.string,
    id: PropTypes.string
  }),
  isDisabled: PropTypes.bool.isRequired
};
