import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { roundToNearestMinutes, addSeconds, isValid } from 'date-fns';
import { fromZonedTime, toZonedTime, format } from 'date-fns-tz';
import { formatToTimeZone } from 'date-fns-timezone';
import WaypointSwitcher from './WaypointSwitcher';

export default class PrebookingTime extends React.Component {
  constructor(props) {
    super(props);

    this.minTime = fromZonedTime(
      roundToNearestMinutes(addSeconds(new Date(), props.prebookingThresholdMin), { nearestTo: 5 }),
      props.timezone.identifier
    );
    this.maxTime = fromZonedTime(
      roundToNearestMinutes(addSeconds(new Date(), props.prebookingThresholdMax), { nearestTo: 5 }),
      props.timezone.identifier
    );

    this.handleWaypointChange = this.handleWaypointChange.bind(this);
    this.handlePrebookingChange = this.handlePrebookingChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);

    this.browserTimezone = format(new Date(), 'z');
    this.productTimezone = format(new Date(), 'z', { timeZone: props.timezone.identifier });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ prebooking: newProps.prebooking });
  }

  handlePrebookingChange(event) {
    const prebooking = event.target.checked;
    const time = this.props.requestedTime || this.minTime;
    this.props.handleUpdate(prebooking ? time : null, this.props.requestedTimeForWaypoint);
  }

  handleTimeChange(timeCandidate) {
    if (!isValid(new Date(timeCandidate))) {
      return;
    }

    const timeRoundedToFiveMinuteInterval = roundToNearestMinutes(timeCandidate, { nearestTo: 5 });
    const timeCandidateInUtc = fromZonedTime(timeRoundedToFiveMinuteInterval, this.props.timezone.identifier);

    // Can be removed once https://github.com/Hacker0x01/react-datepicker/pull/1718 is merged and released
    if (timeCandidateInUtc < this.minTime) {
      this.props.handleUpdate(this.minTime, this.props.requestedTimeForWaypoint);
    } else if (timeCandidateInUtc > this.maxTime) {
      this.props.handleUpdate(this.maxTime, this.props.requestedTimeForWaypoint);
    } else {
      this.props.handleUpdate(timeCandidateInUtc, this.props.requestedTimeForWaypoint);
    }
  }

  handleWaypointChange(waypoint) {
    this.props.handleUpdate(this.props.requestedTime, waypoint);
  }

  renderTimezoneInfo() {
    if (this.browserTimezone === this.productTimezone) {
      return null;
    }

    const formattedTimezone = formatToTimeZone(new Date(), 'z ([UTC]Z)', {
      timeZone: this.props.timezone.identifier
    });

    return (
      <div className="input-group-append">
        <span className="input-group-text">{`${window.locales.BookingWizard.timezone}: ${formattedTimezone}`}</span>
      </div>
    );
  }

  renderWaypointSwitch() {
    if (!this.props.allowDestinationTime) {
      return null;
    }

    return (
      <div className="col-12 col-md-3 text-right">
        <div className="mt-2">
          <WaypointSwitcher
            waypoint={this.props.requestedTimeForWaypoint}
            handleUpdate={this.handleWaypointChange}
            originLabel={window.locales.BookingWizard.originTime}
            destinationLabel={window.locales.BookingWizard.destinationTime}
            disabled={!this.props.prebooking}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="form-group">
        <label htmlFor="prebooking" className="col-form-label">
          {window.locales.BookingWizard.prebooking}
        </label>
        <div className="row">
          <div className="col-12 col-md-9">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <label htmlFor="prebooking" className="custom-control custom-checkbox">
                    <input
                      id="prebooking"
                      onChange={this.handlePrebookingChange}
                      checked={this.props.prebooking}
                      className="custom-control-input"
                      type="checkbox"
                    />
                    <span className="custom-control-label" />
                  </label>
                </div>
                <DatePicker
                  className="form-control input-xl"
                  showTimeSelect
                  timeIntervals={5}
                  timeFormat="p"
                  dateFormat="Pp"
                  onChange={this.handleTimeChange}
                  onFocus={(event) => event.target.select()}
                  selected={
                    this.props.requestedTime
                      ? toZonedTime(this.props.requestedTime, this.props.timezone.identifier)
                      : null
                  }
                  timeCaption={window.locales.BookingWizard.PickupTime.time}
                  disabled={!this.props.prebooking}
                  maxDate={this.maxTime}
                  minDate={this.minTime}
                />
                {this.renderTimezoneInfo()}
              </div>
            </div>
          </div>
          {this.renderWaypointSwitch()}
        </div>
      </div>
    );
  }
}

PrebookingTime.propTypes = {
  prebooking: PropTypes.bool.isRequired,
  prebookingThresholdMin: PropTypes.number.isRequired,
  prebookingThresholdMax: PropTypes.number.isRequired,
  timezone: PropTypes.shape({
    identifier: PropTypes.string,
    utc_offset: PropTypes.number
  }),
  handleUpdate: PropTypes.func.isRequired,
  allowDestinationTime: PropTypes.bool,
  requestedTime: PropTypes.instanceOf(Date),
  requestedTimeForWaypoint: PropTypes.oneOf(['origin', 'destination'])
};
