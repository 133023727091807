import $ from 'jquery';
import { extractCSRFTokenFromPage } from './generic_helpers';

export default class StellwerkAPI {
  constructor(productId) {
    this.productId = productId;

    this.defaultHeaders = {
      'X-Api-Version': 1,
      'X-CSRF-Token': extractCSRFTokenFromPage()
    };
  }

  createRideInquiry(rideInquiryParams) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/ride_inquiry`,
      headers: this.defaultHeaders,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({ data: rideInquiryParams })
    });
  }

  createRide(rideParams) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/rides`,
      headers: this.defaultHeaders,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({ data: rideParams })
    });
  }

  getRide(rideId) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/rides/${rideId}`,
      headers: this.defaultHeaders,
      method: 'GET',
      dataType: 'json'
    });
  }

  cancelRide(rideId) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/rides/${rideId}/cancellation`,
      headers: this.defaultHeaders,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8'
    });
  }

  bookRide(rideId, bookingParams) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/rides/${rideId}/booking`,
      headers: this.defaultHeaders,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({ data: bookingParams })
    });
  }

  findUser(phoneNumber) {
    return $.ajax({
      url: `/api/admin/users/${phoneNumber}`,
      headers: this.defaultHeaders,
      method: 'GET',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8'
    });
  }

  fetchUserBootstrap(userId) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/users/${userId}/booking_wizard_bootstrap`,
      headers: this.defaultHeaders,
      method: 'GET',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8'
    });
  }

  autoCompleteUser(searchTerm) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/users/autocomplete`,
      headers: this.defaultHeaders,
      method: 'GET',
      data: {
        term: searchTerm
      },
      dataType: 'json',
      contentType: 'application/json; charset=utf-8'
    });
  }

  createUser(userParams) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/users`,
      headers: this.defaultHeaders,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({ data: userParams })
    });
  }

  getSmsMessagesForNumber(phoneNumber) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/sms_messages/${phoneNumber}`,
      headers: this.defaultHeaders,
      method: 'GET',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8'
    });
  }

  markSmsConversationAsRead(phoneNumber) {
    return $.ajax({
      url: `/api/admin/products/${this.productId}/sms_messages/${phoneNumber}/mark_as_read`,
      headers: this.defaultHeaders,
      method: 'PATCH',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8'
    });
  }

  sendSmsMessage(phoneNumber, message) {
    const smsParams = {
      content: message,
      phone_number: phoneNumber
    };

    return $.ajax({
      url: `/api/admin/products/${this.productId}/sms_messages`,
      headers: this.defaultHeaders,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({ data: smsParams })
    });
  }

  updateAccountSettings(settingsUpdateParams) {
    return $.ajax({
      url: `/api/admin/account/settings`,
      headers: this.defaultHeaders,
      method: 'PATCH',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({ data: settingsUpdateParams })
    });
  }
}
