import React from 'react';
import PropTypes from 'prop-types';
import { generateComponentIdentifier } from '../../lib/booking_helpers';

export default class BooleanSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.identifier = generateComponentIdentifier();
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(event) {
    if (this.props.enforceSelection === true) return;

    this.props.handleUpdate(event.currentTarget.value === 'true');
  }

  classForValue(value) {
    return value === this.props.value ? 'active' : '';
  }

  disableSelection(value) {
    if (this.props.enforceSelection === false) return '';

    return value !== this.props.value ? 'disabled' : '';
  }

  render() {
    const containerFixedClasses = 'btn-group btn-group-toggle';
    const containerConfiguredClasses = this.props.classNameContainer || 'btn-group-sm';
    const labelFixedClasses = 'btn btn-secondary';
    const labelConfiguredClasses = this.props.classNameLabel;
    const labelTrueCombinedClasses = [labelFixedClasses, labelConfiguredClasses, this.classForValue(true), this.disableSelection(true)].join(' ');
    const labelFalseCombinedClasses = [labelFixedClasses, labelConfiguredClasses, this.classForValue(false), this.disableSelection(false)].join(' ');
    return (
      <div className={[containerFixedClasses, containerConfiguredClasses].join(' ')}>
        <label htmlFor={`true-value-${this.identifier}`} className={labelTrueCombinedClasses}>
          <input
            id={`true-value-${this.identifier}`}
            name={`boolean-${this.identifier}`}
            type="radio"
            onChange={this.handleToggle}
            value="true"
            checked={this.props.value === true}
            disabled={this.props.disabled}
          />
          {this.props.trueTitle || window.locales.BookingWizard.BooleanSwitch.yes}
        </label>
        <label htmlFor={`false-value-${this.identifier}`} className={labelFalseCombinedClasses}>
          <input
            id={`false-value-${this.identifier}`}
            name={`boolean-${this.identifier}`}
            type="radio"
            onChange={this.handleToggle}
            value="false"
            checked={this.props.value === false}
            disabled={this.props.disabled}
          />
          {this.props.falseTitle || window.locales.BookingWizard.BooleanSwitch.no}
        </label>
      </div>
    );
  }
}

BooleanSwitch.defaultProps = {
  disabled: false,
  enforceSelection: false,
  classNameContainer: null,
  classNameLabel: null
};

BooleanSwitch.propTypes = {
  value: PropTypes.bool,
  enforceSelection: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  trueTitle: PropTypes.string,
  falseTitle: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string
};
