import $ from 'jquery';

const AdministrationPersonalDiscounts = {
  init() {
    this.newPersonalDiscountformForm();
  },

  newPersonalDiscountformForm() {
    const personalDiscountForm = $('form#new_personal_discount');
    if (personalDiscountForm.length === 0) return;

    const discountTypeSelect = $('.js-discount-type-select');
    discountTypeSelect.on('change', (e) => {
      $(e.target).parents('form').submit();
    });
  }
}

export default AdministrationPersonalDiscounts;
