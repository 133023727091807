import localization from '../utils/localization';

let gmapsIsLoaded = false;
let gmapsIsLoading = false;

const callbacks = [];

function onLoad() {
  gmapsIsLoaded = true;
  callbacks.forEach((cb) => cb());
}

function preload() {
  const locale = localization.getLocale();
  const key = import.meta.env.VITE_GM_API_KEY;
  const libraries = ['places', 'geometry'].join(',');
  const language = locale;
  const region = locale;
  const globalCallbackName = '__gmapsReadyCallback';
  const scriptTag = document.createElement('script');
  const src = `https://maps.google.com/maps/api/js?key=${key}&region=${region}&language=${language}&libraries=${libraries}&callback=${globalCallbackName}`;

  if (!key) throw 'No Google Maps API Key specified!';

  window[globalCallbackName] = onLoad;

  scriptTag.src = src;
  document.body.appendChild(scriptTag);
  gmapsIsLoading = true;
}

function ready(callback) {
  if (gmapsIsLoaded) return callback();

  callbacks.push(callback);

  if (!gmapsIsLoading) preload();
  return true;
}

export default { ready };
