import React from 'react';
import PropTypes from 'prop-types';

export default class TextOption extends React.Component {
  constructor(props) {
    super(props);

    this.handleTextChange = this.handleTextChange.bind(this);
  }

  handleTextChange(event) {
    const { value } = event.target;
    this.props.handleUpdate(value === null ? '' : value);
  }

  render() {
    return (
      <input
        className="form-control form-control-sm"
        type="text"
        value={this.props.value || ''}
        onChange={this.handleTextChange}
        disabled={this.props.disabled}
      />
    );
  }
}

TextOption.defaultProps = {
    disabled: false
};

TextOption.propTypes = {
    value: PropTypes.string,
    handleUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

