import React from 'react';
import PropTypes from 'prop-types';

export default class Card extends React.Component {
  renderTitle() {
    if (!this.props.title) return null;

    return (
      <div className="card-header">
        <span className="title">{this.props.title}</span>
      </div>
    );
  }

  render() {
    return (
      <div className="card">
        {this.renderTitle()}
        <div className="card-body">{this.props.children}</div>
      </div>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string
};
