import $ from 'jquery';

const ManagementProviders = {
  init() {
    this.handleTwilioSidChange();
  },

  handleTwilioSidChange() {
    const twilioSidField = $('.js-twilio-messaging-services');
    if (!twilioSidField.length) return;

    twilioSidField.on('change', this.handleSidUpdate);
  },

  handleSidUpdate(event) {
    const selectedElement = event.params.args.data.element;
    const { phoneNumber } = selectedElement.dataset;
    const phoneInput = $('.js-provider-sms-support-number');

    if (phoneNumber) {
      const iti = window.intlTelInputGlobals.getInstance(phoneInput.get(0));
      iti.setNumber(phoneNumber);
    } else {
      phoneInput.val('');
      window.setTimeout(() => phoneInput.focus(), 100);
    }
  }
}

export default ManagementProviders;
