import React from 'react';
import PropTypes from 'prop-types';

export default class Alert extends React.Component {
  renderTitle() {
    if (!this.props.title) return null;
    return <strong className="mr-1">{this.props.title}</strong>;
  }

  icon() {
    switch (this.props.type) {
      case 'warning':
        return 'fa fa-exclamation-triangle';
      case 'success':
        return 'fa fa-thumbs-up';
      case 'danger':
        return 'fa fa-exclamation-triangle';
      case 'primary':
        return 'fa fa-info-circle';
    }
  }

  render() {
    return (
      <div className={`alert alert-icon alert-${this.props.type}`}>
        <div className="icon">
          <i className={this.icon()} />
        </div>

        <div className="message">
          {this.renderTitle()}
          {this.props.children}
        </div>
      </div>
    );
  }
}

Alert.propTypes = {
  type: PropTypes.oneOf(['warning', 'success', 'danger', 'primary']).isRequired,
  title: PropTypes.string
};
