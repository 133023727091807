import $ from 'jquery';

const AdministrationRematchingAttempts = {
  init() {
    this.rideSelectButtons();
    this.taskListSelectButtons();
    this.spinnerReload();
  },

  spinnerReload() {
    const spinner = $('.js-spinner-reload');
    if (!spinner.length) return;

    window.setTimeout(() => { window.location.reload(); }, 7500);
  },

  rideSelectButtons() {
    const checkAllButton = $('.js-check-all-rides');
    const unCheckAllButton = $('.js-uncheck-all-rides');
    if (!checkAllButton.length && !unCheckAllButton.length) return;

    const selectBoxes = $('.js-select-ride:enabled');

    checkAllButton.on('click', () => {
      selectBoxes.prop('checked', true);
    });

    unCheckAllButton.on('click', () => {
      selectBoxes.prop('checked', false);
    });
  },

  taskListSelectButtons() {
    const checkAllButton = $('.js-check-all-task-lists');
    const unCheckAllButton = $('.js-uncheck-all-task-lists');
    if (!checkAllButton.length && !unCheckAllButton.length) return;

    const selectBoxes = $('.js-select-task-list:enabled');

    checkAllButton.on('click', () => {
      selectBoxes.prop('checked', true);
    });

    unCheckAllButton.on('click', () => {
      selectBoxes.prop('checked', false);
    });
  }
}

export default AdministrationRematchingAttempts;
