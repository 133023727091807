import { zip } from 'lodash';

const coordsFromLinearRing = (linearRing) => {
  const coords = linearRing.map((coordinate) => ({
    lat: coordinate[1],
    lng: coordinate[0]
  }));

  // geojson provides a closed linestring, google wants an open one
  coords.pop();

  return coords;
};

export const latLngFromObj = (obj) => {
  if (!obj) {
    return null;
  }

  return { lat: obj.lat, lng: obj.lng };
};

/*
 * This converts a geojson-formatted Multipolygon to
 * a list of paths, usable for gmaps
 */
export const convertMultiPolygonToPaths = (geojson) => {
  if (geojson.type === 'Polygon') {
    return [coordsFromLinearRing(geojson.coordinates[0])];
  }
  return geojson.coordinates.map((polygon) => polygon.map((linearRing) => coordsFromLinearRing(linearRing)));
};

const extractValue = (val) => {
  if (typeof val === 'function') {
    return val();
  }
  return val;
};

export const isEqualCoordinate = (cooOne, cooTwo) =>
  extractValue(cooOne.lat) === extractValue(cooTwo.lat) && extractValue(cooOne.lng) === extractValue(cooTwo.lng);

export const isEqualPath = (pathOne, pathTwo) => {
  if (pathOne.length !== pathTwo.length) {
    return false;
  }

  return zip(pathOne, pathTwo).every((pair) => isEqualCoordinate(...pair));
};

export const pointOnCircle = (x, y, radius, itemCount, index) => {
  const twoPi = Math.PI * 2;

  const angle = (twoPi / itemCount) * (index + 1);

  const newX = x + Math.cos(angle) * radius;
  const newY = y + Math.sin(angle) * radius;

  return [newX, newY];
};

export const itemSVG = (color, index) =>
  `data:image/svg+xml,<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" fill="${color}">
  <circle cx="25" cy="25" r="25"/>
  <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="40px" font-family="Arial" dy=".3em">${index}</text>
</svg>`.replace('#', '%23');
