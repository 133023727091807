import $ from 'jquery';

const AdministrationPromoCodes = {
  init() {
    this.promoCodeForm();
  },

  promoCodeForm() {
    const limitRedemptionsCheckBox = $('.js-toggle-max-redemptions');

    limitRedemptionsCheckBox.on('change', (e) => {
      $('.js-max-redemptions').prop('disabled', !e.currentTarget.checked);
    });
  }
}

export default AdministrationPromoCodes;
