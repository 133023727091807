import $ from 'jquery';
import loadData from '../../lib/bootstrapped_data';

const AdministrationServiceCredits = {
  init() {
    this.serviceCreditForm();
  },
  serviceCreditForm() {
    const form = $("form#new_service_credit");
    const context = {
      form,
      channelSelect: form.find('select#service_credit_channel'),
      packageSelect: form.find('select#service_credit_service_credit_package_id'),
      paymentMethodSelect: form.find('select#service_credit_payment_method_type'),
      valueInput:    form.find('input#service_credit_value'),
      costInput:     form.find('input#service_credit_cost')
    }

    context.packageSelect.on('change', () => { this.renderFormState(context); });
    context.channelSelect.on('change', () => { this.renderFormState(context); });

    this.renderFormState(context);
  },
  toggleMoneyInputField(field, formState, displayState) {
    const renderedMoneyInput = field.siblings('div').find('.js-rendered-money-input');

    field.prop('disabled', this.disabledStateBasedOnDisplayState(formState));
    renderedMoneyInput.prop('disabled', this.disabledStateBasedOnDisplayState(formState));
    field.parents('.form-group').toggle(displayState);
  },
  toggleInputField(field, displayState) {
    field.prop('disabled', this.disabledStateBasedOnDisplayState(displayState));
    field.parents('.form-group').toggle(displayState);
  },
  disabledStateBasedOnDisplayState(displayState) {
    const discountInUse = loadData('discount_type_in_use');

    if (discountInUse) {
      return true;
    }
    return !displayState;
  },
  renderFormState(context) {
    const channel = context.channelSelect.val();
    const packageSelected = context.packageSelect.val();

    // 1) if any package selected, make fields readOnly
    // 2) show and enable cost only if channel 'sold_by_operator' is selected
    const showCostField = channel === 'sold_by_operator';
    const enableValueField = !packageSelected;
    const enableCostField = showCostField && !packageSelected;

    this.toggleMoneyInputField(context.costInput, enableCostField, showCostField);
    this.toggleMoneyInputField(context.valueInput, enableValueField, true);
    this.toggleInputField(context.paymentMethodSelect, showCostField);
  }
}

export default AdministrationServiceCredits;
