import React from 'react';
import PropTypes from 'prop-types';

export const pageVisibilityApi = () => {
    let hidden;
    let visibilityChange;

    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }

    return { hidden, visibilityChange };
};

const { hidden, visibilityChange } = pageVisibilityApi();

export default class VisibilityManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: true
        };
    }

    componentDidMount() {
        document.addEventListener(visibilityChange, () => {
            this.handleVisibilityChange()
        }, false);
    }

    componentWillUnmount() {
        document.removeEventListener(visibilityChange, () => {
            this.handleVisibilityChange()
        }, false);
    }

    handleVisibilityChange() {
        if (document[hidden]) {
            this.setVisibility(false);
        } else {
            this.setVisibility(true);
        }
    };

    setVisibility(flag) {
        this.setState(prevState => {
            if (prevState.isVisible === flag) return null;
            return { isVisible: flag };
        });
    }

    render() {
        return this.props.children(
            this.state.isVisible
        );
    }
}

VisibilityManager.defaultProps = {};

VisibilityManager.propTypes = {
    children: PropTypes.func.isRequired
};
