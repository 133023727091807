import React from 'react';
import PropTypes from 'prop-types';

import { Polyline } from 'react-leaflet';

import Station from './markers/Station';

const lineStyles = {
  color: '#333',
  weight: 3,
  opacity: '0.6'
};

export default class Line extends React.Component {
  linePoints() {
    return this.props.line.line_stops.map((lineStop) => ({ lat: lineStop.lat, lng: lineStop.lng }));
  }

  renderStations() {
    return this.props.line.line_stops.map((lineStop) => <Station key={lineStop.id} station={lineStop} />);
  }

  render() {
    return (
      <>
        <Polyline positions={this.linePoints()} {...lineStyles} />
        {this.renderStations()}
      </>
    );
  }
}

Line.propTypes = {
  line: PropTypes.shape({
    id: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string,
    line_stops: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        created_at: PropTypes.string,
        id: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
        location_name: PropTypes.string,
        relative_time: PropTypes.number,
        supports_dropoff: PropTypes.bool,
        supports_pass_through: PropTypes.bool,
        supports_pickup: PropTypes.bool,
        tier: PropTypes.number,
        type: PropTypes.string,
        updated_at: PropTypes.string
      })
    )
  })
};
