// Based on https://github.com/tomiford/bootstrap-overflow-navs
import $ from 'jquery';

const setupNavigationOverflow = () => {
  const ul = $('.main-operator-menu');

  if (ul.length !== 1) return;

  const collapsed = $('.be-toggle-top-header-menu').is(':visible');
  if (collapsed) return;

  // This magic number was taken from inspector by looking at how many pixels the logo and the right menu items
  // use. If the logo or other stuff changes, this needs to be readjusted. Unfortunately, since the menu is in a
  // weird state when the page loads, we cannot determine those numbers programmatically :(
  const pixelsTakenByOtherMenuItems = 740;
  const availableMenuWidth = ul.parents('.container-fluid').width() - pixelsTakenByOtherMenuItems;

  // Check if the overflow dropdown exists and if not, create a new one
  let dropdown = $('li.overflow-nav', ul);
  if (!dropdown.length) {
    dropdown = $('<li class="overflow-nav dropdown"></li>');
    dropdown.append(
      $(
        `<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">
            <div class="btn btn-navbar">...</div>
          </a>`
      )
    );
    dropdown.append($('<div class="dropdown-menu"></div>'));
  }

  // Get the width of the navbar, need to add together <li>s as the ul wraps in bootstrap
  let menuItemWidth = 0;
  ul.children('li').each((_, item) => {
    menuItemWidth += $(item).outerWidth();
  });

  if (menuItemWidth >= availableMenuWidth) {
    let decreasingWidth = menuItemWidth;

    // Loop through each non-dropdown li in the ul menu from right to left (using .get().reverse())
    const listItems = $('li', ul).not('.dropdown').get().reverse();

    listItems.forEach((item) => {
      const $item = $(item);

      if (decreasingWidth >= availableMenuWidth) {
        decreasingWidth -= $item.outerWidth();

        const $linkItem = $item.children('a');
        $linkItem.removeClass('nav-link').addClass('dropdown-item');
        dropdown.children('div.dropdown-menu').prepend($linkItem);
        $item.remove();
      }
    });
  }

  if (dropdown.children('div.dropdown-menu').children().length > 0) {
    ul.append(dropdown);
  }
};

export default setupNavigationOverflow;
