import React from 'react';
import PropTypes from 'prop-types';
import { GeoJSON } from 'react-leaflet';
import { isEmpty, pick } from 'lodash';

export default class Area extends React.Component {
  geoJSONStyle() {
    return pick(this.props, ['color', 'opacity', 'fillColor', 'fillOpacity', 'stroke', 'weight']);
  }

  render() {
    if (isEmpty(this.props.area)) return null;

    return <GeoJSON data={this.props.area} style={this.geoJSONStyle()} />;
  }
}

Area.defaultProps = {
  color: '#333',
  opacity: 0.8,
  fillColor: '#333',
  fillOpacity: 0.5
};

Area.propTypes = {
  area: PropTypes.object.isRequired,
  color: PropTypes.string,
  opacity: PropTypes.number,
  fillColor: PropTypes.string,
  stroke: PropTypes.string,
  weight: PropTypes.number,
  fillOpacity: PropTypes.number
};
