import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

import loadData from '../../lib/bootstrapped_data';
import Area from '../../components/here_maps/layers/Area';
import HereMap from '../../components/here_maps/HereMap.jsx';

const AdministrationZones = {
  init() {
    this.renderMaps();
  },

  renderArea() {
    const zones = loadData('zones').data;
    if (!zones && zones.length === 0) return null;

    return zones.map((zone) => <Area key={`zone-${zone.id}`} area={zone.area} />);
  },

  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;

    ReactDOM.render(
      <HereMap autoZoom noTraffic boundingBox={loadData('bbox')}>
        {this.renderArea()}
      </HereMap>,
      mapEl.get(0)
    );
  }
}

export default AdministrationZones;
