import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Tooltip } from 'react-leaflet';
import { DivIcon } from 'leaflet';
import stationSVG from '../../../images/maps/svg_icons/station.svg';

export default class Station extends React.Component {
  getStationName(station) {
    if (station.station_category) {
      return `${station.location_name} (${station.station_category.title})`;
    }

    return station.location_name;
  }

  render() {
    const { lat, lng, active, station_category: stationCategory } = this.props.station;
    const color = stationCategory ? stationCategory.color : '#000000';

    const opacity = active ? 1.0 : 0.4;
    const size = 24;

    const $svg = $(stationSVG);
    $svg.css({ opacity });
    $svg.find('.colorize').css({ fill: color });

    const icon = new DivIcon({
      html: $svg.get(0),
      iconSize: [size, size],
      iconAnchor: [size / 2, size],
      className: 'station-marker'
    });

    return (
      <Marker position={[lat, lng]} icon={icon} zIndexOffset={800} type="station">
        <Tooltip direction="top" offset={[0, -size]}>
          <i className="fa fa-dot-circle-o mr-1" />
          {this.getStationName(this.props.station)}
        </Tooltip>
      </Marker>
    );
  }
}

Station.propTypes = {
  station: PropTypes.object.isRequired
};
