import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import SMSSingleUserConversation from '../../components/SMSSingleUserConversation';
import loadData from '../../lib/bootstrapped_data';

const AdministrationDrivers = {
  init() {
    this.initSmsMessenger();
    this.initDriverForm();
  },

  initSmsMessenger() {
    const smsContainer = window.document.getElementById('js-sms-user-view');
    if (!smsContainer) return;

    const conversation = loadData('conversation');
    const timezone = loadData('timezone');
    ReactDOM.render(<SMSSingleUserConversation conversation={conversation} timezone={timezone} />, smsContainer);
  },

  initDriverForm() {
    const lastName = $('.js-driver-last-name');

    if (!lastName.length) return;

    lastName.on('blur', this.generateDriverUserName);
  },

  generateDriverUserName() {
    const firstName = $('.js-driver-first-name');
    const lastName = $('.js-driver-last-name');
    const userName = $('.js-driver-username');

    if (userName.val() !== '') return;

    const generatedName = `${firstName.val()}${lastName.val()}`.replace(/\W/gi, '').toLowerCase();
    userName.val(generatedName);
  }
}

export default AdministrationDrivers;
