import $ from 'jquery';
import { compact } from 'lodash';

const ManagementProducts = {
  init() {
    this.showFareCalculationFields();
    this.showProhibitParallelRideFields();
  },

  showFareCalculationFields() {
    const fareAdapterSelectFields = $('.js-fare-adapter-selects select');

    if (!fareAdapterSelectFields.length) return;

    fareAdapterSelectFields.on('change', this.handleFareAdapterUpdate);

    // Trigger the function once to show all fields that are currently selected
    this.handleFareAdapterUpdate();
  },

  handleFareAdapterUpdate() {
    const fareAdapterSelectFields = $('.js-fare-adapter-selects select');
    const enabledParameterFields = [];

    fareAdapterSelectFields.each((index, element) => {
      const fields = $(element).children('option:selected').data('required-fields').split(',');

      enabledParameterFields.push(...compact(fields));
    });

    // Hide all fields, so we can only display those fields that we need
    $('.js-fare-adapter-option-fields .row').addClass('d-none');

    enabledParameterFields.forEach((field) => $(`#${field}`).removeClass('d-none'));

    // We do not explicitly disable the fields, as the parameter sanitization will remove
    // all inputs, that are not required by any of the given pricing adapters
  },

  showProhibitParallelRideFields() {
    const prohibitParallelRideAdapterSelectField = $('.js-prohibit-parallel-ride-adapter-select select');

    if (!prohibitParallelRideAdapterSelectField.length) return;

    prohibitParallelRideAdapterSelectField.on('change', this.handleProhibitParallelRide);

    // Trigger the function once to show all fields that are currently selected
    this.handleProhibitParallelRide();
  },

  handleProhibitParallelRide() {
    const prohibitParallelRideAdapterSelectField = $('.js-prohibit-parallel-ride-adapter-select select');

    if (prohibitParallelRideAdapterSelectField.val() === 'pta_scoring') {
      $('.js-prohibit-parallel-ride-adapter-scoring-fields').removeClass('d-none');
    } else {
      $('.js-prohibit-parallel-ride-adapter-scoring-fields').addClass('d-none');
    }
  }
}

export default ManagementProducts;
