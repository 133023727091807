import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import LiveProductMapContainer from '../../components/LiveProductMapContainer';
import VisibilityManager from '../../lib/VisibilityManager';

const AdministrationProducts = {
  init() {
    this.renderMaps();
    this.showProhibitParallelRideFields();
  },

  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;
    ReactDOM.render(
        <VisibilityManager>
          {isVisible => <LiveProductMapContainer liveDataUrl={mapEl.data('live')} isVisible={isVisible} />}
    </VisibilityManager>, mapEl.get(0));
  },

  showProhibitParallelRideFields() {
    const prohibitParallelRideAdapterSelectField = $('.js-prohibit-parallel-ride-adapter-select select');

    if (!prohibitParallelRideAdapterSelectField.length) return;

    prohibitParallelRideAdapterSelectField.on('change', this.handleProhibitParallelRide);

    // Trigger the function once to show all fields that are currently selected
    this.handleProhibitParallelRide();
  },

  handleProhibitParallelRide() {
    const prohibitParallelRideAdapterSelectField = $('.js-prohibit-parallel-ride-adapter-select select');

    if (prohibitParallelRideAdapterSelectField.val() === 'pta_scoring') {
      $('.js-prohibit-parallel-ride-adapter-scoring-fields').removeClass('d-none');
    } else {
      $('.js-prohibit-parallel-ride-adapter-scoring-fields').addClass('d-none');
    }
  }
}

export default AdministrationProducts;
