import $ from 'jquery';

const setupKeepOpenForDropdowns = () => {
  $('.keep-open > .dropdown-menu').on('click', (e) => {
    e.stopPropagation();
  });
};

const setupSearchDropdowns = () => {
  const searchForm = $('.search-form');
  if (searchForm.length === 0) {
    return;
  }

  $('.search-form .dropdown').on('shown.bs.dropdown', (e) => {
    const target = $(e.target);

    const input = target.find('input[type="search"], input[type="text"], input[type="date"]');

    if (input.length !== 0) {
      input.focus();
      input.select();
    }
  });

  $('.search-form .dropdown .dropdown-menu input').on('change', (e) => {
    const target = $(e.target);

    const toggleButton = target.parents('.dropdown').children('.dropdown-toggle');

    if (!toggleButton.hasClass('btn-warning')) {
      toggleButton.removeClass('btn-primary btn-secondary');
      toggleButton.addClass('btn-warning');
      toggleButton.text(`* ${toggleButton.text()}`);
    }
  });
};

export { setupKeepOpenForDropdowns, setupSearchDropdowns };
