import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MessageHeader extends Component {
  currentConversationTitle() {
    const { conversation } = this.props;

    if (conversation.name) {
      return (
        <div className="user">
          <h2>{conversation.name}</h2>
          <div className="phone-number">{`+${conversation.number}`}</div>
        </div>
      );
    }
    return (
      <div className="user">
        <h2>{`+${conversation.number}`}</h2>
      </div>
    );
  }

  render() {
    if (!this.props.conversation) {
      return null;
    }
    return <div className="title">{this.currentConversationTitle()}</div>;
  }
}

MessageHeader.propTypes = {
  conversation: PropTypes.object
};
