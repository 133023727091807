import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

import loadData from '../../lib/bootstrapped_data';
import HereMap from '../../components/here_maps/HereMap.jsx';
import Area from '../../components/here_maps/layers/Area.jsx';

const ManagementTicketingZones = {
  init() {
    this.renderMaps();
  },

  renderArea() {
    const ticketingZones = loadData('ticketing_zones').data;
    if (!ticketingZones && ticketingZones.length === 0) return null;

    return ticketingZones.map((ticketingZone) => (
      <Area
        key={ticketingZone.id}
        area={ticketingZone.area}
        color="#ff2b79"
        opacity={0.8}
        fillColor="#ff2b79"
      />
    ));
  },

  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;

    ReactDOM.render(
      <HereMap autoZoom noTraffic boundingBox={loadData('bbox')}>
        {this.renderArea()}
      </HereMap>,
      mapEl.get(0)
    );
  }
}

export default ManagementTicketingZones;
