import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MessageList from './sms/MessageList';
import NewMessageInput from './sms/NewMessageInput';
import StellwerkAPI from '../lib/stellwerk_api';
import { getProductIdFromURL } from '../lib/generic_helpers';

export default class SMSSingleUserConversation extends Component {
  constructor(props) {
    super(props);

    this.stellwerkAPI = new StellwerkAPI(getProductIdFromURL());

    this.state = { messages: [] };

    this.sendNewMessage = this.sendNewMessage.bind(this);
    this.markConversationAsRead = this.markConversationAsRead.bind(this);
  }

  componentDidMount() {
    const { number } = this.props.conversation;

    this.stellwerkAPI.getSmsMessagesForNumber(number).done((response) => {
      this.setState({ messages: response.data });
    });
  }

  sendNewMessage(content) {
    const { number } = this.props.conversation;
    const { messages } = this.state;

    this.stellwerkAPI
      .sendSmsMessage(number, content)
      .done((response) => {
        messages.push(response.data);
        this.setState({ messages });

        this.markConversationAsRead();
      })
      .fail((response) => {
        console.log('could not send SMS', response);
      });
  }

  currentConversationHasUnreads() {
    return (
      this.state.messages &&
      this.state.messages.some((message) => message.direction === 'incoming' && message.read_at === null)
    );
  }

  markConversationAsRead() {
    const { number } = this.props.conversation;

    this.stellwerkAPI.markSmsConversationAsRead(number).done(() => {
      this.stellwerkAPI.getSmsMessagesForNumber(number).done((response) => {
        this.setState({ messages: response.data });
      });
    });
  }

  render() {
    return (
      <div className="sms-chat">
        <div className="chat-window">
          <MessageList messages={this.state.messages} timezone={this.props.timezone}/>
          <NewMessageInput
            onSubmit={this.sendNewMessage}
            markAsRead={this.markConversationAsRead}
            unreads={this.currentConversationHasUnreads()}
          />
        </div>
      </div>
    );
  }
}

SMSSingleUserConversation.propTypes = {
  conversation: PropTypes.object.isRequired,
  timezone: PropTypes.object.isRequired
};
