import React from 'react';
import PropTypes from 'prop-types';
import { TileLayer } from 'react-leaflet';

export default class HereTrafficLayer extends React.Component {
  constructor(props) {
    super(props);

    this.hereApiKey = import.meta.env.VITE_HERE_API_KEY_STELLWERK_MAPS;
    this.updateTrafficLayer = this.updateTrafficLayer.bind(this);
  }

  componentDidMount() {
    this.updateTrafficLayer();
  }

  updateTrafficLayer() {
    window.setTimeout(() => {
      this.trafficLayer.leafletElement.redraw();

      this.updateTrafficLayer();
    }, this.props.refreshTime * 1000);
  }

  trafficLayerURL() {
    return `https://traffic.maps.hereapi.com/v3/flow/mc/{z}/{x}/{y}/png?apiKey=${this.hereApiKey}`;
  }

  render() {
    return (
      <TileLayer
        ref={(c) => {
          this.trafficLayer = c;
        }}
        url={this.trafficLayerURL()}
      />
    );
  }
}

HereTrafficLayer.propTypes = {
  refreshTime: PropTypes.number
};

HereTrafficLayer.defaultProps = {
  refreshTime: 180
};
