import $ from 'jquery';
import loadData from '../../lib/bootstrapped_data';
import { setupRecurrenceRulesFormButtons } from '../../lib/form_helpers';

const AdministrationPersonalDiscountTypes = {
  init() {
    setupRecurrenceRulesFormButtons();
    this.personalDiscountTypeForm();
  },
  personalDiscountTypeForm() {
    const noNewForm = $('form#new_personal_discount_type').length === 0;
    const noEditForm = $('form.edit_personal_discount_type').length === 0;
    if (noNewForm && noEditForm) return;

    $('select#personal_discount_type_purchasable').on('change', (event) => {
      this.setPriceInputField($(event.target).val() === 'true');
    });

    $('select#personal_discount_type_time_validity_mode').on('change', (event) => {
      const timeValidityMode = $(event.target).val();

      if (timeValidityMode === 'relative_to_creation') {
        this.toggleInputField('select#personal_discount_type_default_duration', true);
        this.toggleInputField('input#personal_discount_type_fixed_start_date', false);
        this.toggleInputField('input#personal_discount_type_fixed_end_date', false);
      } else if (timeValidityMode === 'fixed_start_and_end') {
        this.toggleInputField('select#personal_discount_type_default_duration', false);
        this.toggleInputField('input#personal_discount_type_fixed_start_date', true);
        this.toggleInputField('input#personal_discount_type_fixed_end_date', true);
      }
    });

    $('select#personal_discount_type_discount_type').on('change', (event) => {
      const discountType = $(event.target).val();

      if (discountType === 'absolute') {
        this.toggleInputField('input#personal_discount_type_relative_discount', false);
        this.toggleMoneyInputField('input#personal_discount_type_absolute_discount', true);
        this.toggleMoneyInputField('input#personal_discount_type_fixed_price_per_passenger', false);
        this.toggleMoneyInputField('input#personal_discount_type_fixed_price_per_ride', false);
      } else if (discountType === 'relative') {
        this.toggleInputField('input#personal_discount_type_relative_discount', true);
        this.toggleMoneyInputField('input#personal_discount_type_absolute_discount', false);
        this.toggleMoneyInputField('input#personal_discount_type_fixed_price_per_passenger', false);
        this.toggleMoneyInputField('input#personal_discount_type_fixed_price_per_ride', false);
      } else if (discountType === 'fixed_per_ride') {
        this.toggleInputField('input#personal_discount_type_relative_discount', false);
        this.toggleMoneyInputField('input#personal_discount_type_absolute_discount', false);
        this.toggleMoneyInputField('input#personal_discount_type_fixed_price_per_passenger', false);
        this.toggleMoneyInputField('input#personal_discount_type_fixed_price_per_ride', true);
      } else if (discountType === 'fixed_per_passenger') {
        this.toggleInputField('input#personal_discount_type_relative_discount', false);
        this.toggleMoneyInputField('input#personal_discount_type_absolute_discount', false);
        this.toggleMoneyInputField('input#personal_discount_type_fixed_price_per_passenger', true);
        this.toggleMoneyInputField('input#personal_discount_type_fixed_price_per_ride', false);
      }
    });

    $('select#personal_discount_type_validity').on('change', (event) => {
      const validityMode = $(event.target).val();

      if (validityMode === 'time') {
        this.toggleInputField('input#personal_discount_type_maximum_usages', false);
        this.toggleTimeValidityModeField(true);
      } else if (validityMode === 'usage') {
        this.toggleInputField('input#personal_discount_type_maximum_usages', true);
        this.toggleTimeValidityModeField(false);
        this.toggleInputField('select#personal_discount_type_default_duration', false);
        this.toggleInputField('input#personal_discount_type_fixed_start_date', false);
        this.toggleInputField('input#personal_discount_type_fixed_end_date', false);
      } else if (validityMode === 'usage_and_time') {
        this.toggleInputField('input#personal_discount_type_maximum_usages', true);
        this.toggleTimeValidityModeField(true);
      } else {
        this.toggleInputField('input#personal_discount_type_maximum_usages', false);
        this.toggleTimeValidityModeField(false);
        this.toggleInputField('select#personal_discount_type_default_duration', false);
        this.toggleInputField('input#personal_discount_type_fixed_start_date', false);
        this.toggleInputField('input#personal_discount_type_fixed_end_date', false);
      }
    });

    // Set up the form once
    $('select#personal_discount_type_validity').prop('disabled', this.disabledStateBasedOnDisplayState(true));
    $('select#personal_discount_type_discount_type').prop('disabled', this.disabledStateBasedOnDisplayState(true));
    $('select#personal_discount_type_purchasable').trigger('change');
    $('select#personal_discount_type_time_validity_mode').trigger('change');
    $('select#personal_discount_type_validity').trigger('change');
    $('select#personal_discount_type_discount_type').trigger('change');
  },

  setPriceInputField(displayState) {
    const priceInput = $('input#personal_discount_type_price');
    const renderedMoneyInput = priceInput.siblings('div').find('.js-rendered-money-input');

    // Price can be changed, even if the discount is in use
    renderedMoneyInput.prop('disabled', !displayState);
    priceInput.prop('disabled', !displayState);
  },

  toggleTimeValidityModeField(displayState) {
    const timeValidityModeField = $('select#personal_discount_type_time_validity_mode');

    this.toggleInputField(timeValidityModeField, displayState);
    if (displayState) {
      timeValidityModeField.trigger('change');
    }
  },

  toggleInputField(selector, displayState) {
    const field = $(selector);

    field.prop('disabled', this.disabledStateBasedOnDisplayState(displayState));
    field.parents('.form-group').toggle(displayState);
  },

  toggleMoneyInputField(selector, displayState) {
    const field = $(selector);
    const renderedMoneyInput = field.siblings('div').find('.js-rendered-money-input');

    field.prop('disabled', this.disabledStateBasedOnDisplayState(displayState));
    renderedMoneyInput.prop('disabled', this.disabledStateBasedOnDisplayState(displayState));
    field.parents('.form-group').toggle(displayState);
  },

  disabledStateBasedOnDisplayState(displayState) {
    const discountInUse = loadData('discount_type_in_use');

    if (discountInUse) {
      return true;
    }
    return !displayState;
  }
}

export default AdministrationPersonalDiscountTypes;
