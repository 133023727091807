import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import SMSSingleUserConversation from '../../components/SMSSingleUserConversation';
import loadData from '../../lib/bootstrapped_data';

const AdministrationUsers = {
  init() {
    this.initSmsMessenger();
    this.initUnlockSwitcher();
  },

  initSmsMessenger() {
    const smsContainer = window.document.getElementById('js-sms-user-view');
    if (!smsContainer) return;

    const conversation = loadData('conversation');
    const timezone = loadData('timezone');
    ReactDOM.render(<SMSSingleUserConversation conversation={conversation} timezone={timezone} />, smsContainer);
  },

  initUnlockSwitcher() {
    const enableUnlockCheckBox = $('.js-toggle-unlock-date');
    if (!enableUnlockCheckBox) return;

    enableUnlockCheckBox.on('change', (e) => {
      $('.js-unlock-date').prop('disabled', !e.currentTarget.checked);
      $('.js-unlock-date').prop('required', e.currentTarget.checked);
    });
  }
}

export default AdministrationUsers;
