import React from 'react';
import PropTypes from 'prop-types';
import PassengerOption from './PassengerOption';

export default class PassengerSelector extends React.Component {
  constructor(props) {
    super(props);

    this.t = window.locales.BookingWizard.PassengerSelector;

    this.changePassengerType = this.changePassengerType.bind(this);
    this.changeFirstName = this.changeString.bind(this, 'first_name');
    this.changeLastName = this.changeString.bind(this, 'last_name');
    this.changeValueInOption = this.changeValueInOption.bind(this);
  }

  changePassengerType(event) {
    const passenger = this.props.currentPassenger || {};

    if (event.target.value === '') {
      this.props.handleUpdate(this.props.passengerIndex, null);
    } else {
      passenger.type = event.target.value;
      if (!this.passengerInitialized()) {
        passenger.options = this.props.availablePassengerOptions.map((option) => {
          const initialValue = { boolean: false, string: '', integer: 0 }[option.data_type];
          return {
            slug: option.slug,
            value: initialValue
          };
        });
      }
      this.props.handleUpdate(this.props.passengerIndex, passenger);
    }
  }

  changeString(optionName, event) {
    const passenger = this.props.currentPassenger || {};
    passenger[optionName] = event.target.value === '' ? null : event.target.value;
    this.props.handleUpdate(this.props.passengerIndex, passenger);
  }

  changeValueInOption(optionSlug, value) {
    const passenger = this.props.currentPassenger || {};
    passenger.options.forEach( (option, index)  => {
      if (option.slug === optionSlug) {
        passenger.options[index].value = value;
      }
    });

    this.props.handleUpdate(this.props.passengerIndex, passenger);
  }

  passengerIsActive() {
    return this.props.currentPassenger &&
           this.props.currentPassenger.type &&
           this.props.currentPassenger.type !== '';
  }

  passengerInitialized() {
    return this.props.currentPassenger &&
           this.props.currentPassenger.options &&
           this.props.currentPassenger.options.length > 0 ;
  }

  passengerIsActiveAndRequiresName() {
    return this.passengerIsActive() &&
           !this.valueForOption('public_transport_ticket')
  }

  valueFor(key) {
    return this.props.currentPassenger && this.props.currentPassenger[key];
  }

  valueForOption(key) {
    if (!this.props.currentPassenger ||
        !this.props.currentPassenger.options ||
        !this.props.currentPassenger.options.length > 0)
    { return null; }

    let ret = false
    this.props.currentPassenger.options.forEach( option =>  {
      if (option.slug === key) {  ret = option.value }
    })
    return ret
  }

  renderPassengerDropdownOptions() {
    return this.props.availablePassengerTypes.map( passengerType => {
      const {slug} = passengerType;
      return(
        <option key={slug} value={slug}>
          {passengerType.localized_name}
        </option>
      );
    });
  }

  renderPassengerDropdown() {
    const selectedValue = this.valueFor('type') || '';

    return (
      <select className='passenger-type-dropdown h-100' data-no-tomselect value={selectedValue} onChange={this.changePassengerType}>
        <option value="" />
        {this.renderPassengerDropdownOptions()}
      </select>
    );
  }

  renderPassengerOptions() {
    return this.props.availablePassengerOptions.map((option) =>
      <PassengerOption
        key={`option-${option.slug}`}
        option={option}
        value={this.valueForOption(option.slug)}
        handleUpdate={this.changeValueInOption}
        disabled={!this.passengerIsActive()}
        passengerIndex={this.props.passengerIndex} // at least for debugging purposes
      />
    );
  }

  renderNameField(elementName, onChangeFunc) {
    const labelId = `${elementName}_id`

    return (
      <label htmlFor={labelId}>
        {this.t[elementName]}
        <input
          className="ml-1"
          type="text"
          value={this.valueFor(elementName) || ''}
          onChange={onChangeFunc}
          disabled={!this.passengerIsActiveAndRequiresName()}
          id = {labelId}
        />
      </label>
    );
  }

  renderNameFields() {
    if (!this.props.nameRequiredIfNoPublicTransportTicket) {
      return null;
    }

    return (
      <div className="row pt-1">
        <div className="col-12 col-xl-1" />
        <div className="col-12 col-xl">
          { this.renderNameField('first_name', this.changeFirstName) }
        </div>
        <div className="col-12 col-xl">
          { this.renderNameField('last_name', this.changeLastName) }
        </div>
      </div>
    );
  }

  render() {
    return (
      <li className="list-group-item">
        <div className="row">
          <div className="col-1 align-self-center pr-0">{`#${this.props.passengerIndex + 1}`}</div>
          <div className="col-2 p-0">{this.renderPassengerDropdown()}</div>
          <div className="col-9 col-xl">
            <div className="container">
              <div className="row">
                {this.renderPassengerOptions()}
              </div>
            </div>
          </div>
        </div>
        {this.renderNameFields()}
      </li>
    );
  }
}

PassengerSelector.propTypes = {
  handleUpdate: PropTypes.func,
  passengerIndex: PropTypes.number,
  currentPassenger: PropTypes.shape({
    type: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool
        ])
      })
  )}),
  availablePassengerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      localized_name: PropTypes.string,
      type: PropTypes.string
  })),
  availablePassengerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      localized_name: PropTypes.string
    })
  ),
  nameRequiredIfNoPublicTransportTicket: PropTypes.bool
};
