import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import loadData from '../../lib/bootstrapped_data';
import Area from '../../components/here_maps/layers/Area';
import HereMap from '../../components/here_maps/HereMap';

const AdministrationMatchingConfigurations = {
  init() {
    this.renderMaps();
  },

  renderArea() {
    const area = loadData('matching_configuration_area');
    if (!area) return null;

    return <Area area={area} />;
  },

  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;

    ReactDOM.render(
      <HereMap autoZoom noTraffic boundingBox={loadData('bbox')}>
        {this.renderArea()}
      </HereMap>,
      mapEl.get(0)
    );
  }
}

export default AdministrationMatchingConfigurations;
