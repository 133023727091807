import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { uniqBy } from 'lodash';
import loadData from '../lib/bootstrapped_data';
import ProductMap from './here_maps/ProductMap';

const UPDATE_INTERVAL = 5000;

export default class LiveProductMapContainer extends React.Component {
  constructor(props) {
    super(props);

    const drtArea = loadData('drt_area') !== undefined ? loadData('drt_area').data : undefined;
    const intermodalArea = loadData('intermodal_area') !== undefined ? loadData('intermodal_area').data : undefined;
    const calculatedRoute = loadData('calculated_route');
    const stations = loadData('stations');
    const places = loadData('places');
    const vehiclePositions = loadData('positions');
    const boundingBox = loadData('bbox');

    this.state = {
      drtArea,
      intermodalArea,
      calculatedRoute,
      boundingBox,
      stations: stations.data,
      places: places.data,
      trail: vehiclePositions && vehiclePositions.data,
      rides: [],
      vehicles: [],
      vehicleCurrentPositions: [],
      vehicleLastPositions: []
    };
  }

  componentDidMount() {
    this.loadLiveData();
  }

  handleLiveDataUpdate(response) {
    const newState = {
      rides: response.data.rides,
      vehicles: response.data.vehicles,
      vehicleCurrentPositions: response.data.vehicle_current_positions,
      vehicleLastPositions: response.data.vehicle_last_positions
    };

    if (this.state.trail) {
      newState.trail = uniqBy([...this.state.trail, ...response.data.vehicle_current_positions], 'id');
    }

    this.setState(newState);

    window.setTimeout(() => this.loadLiveData(), UPDATE_INTERVAL);
  }

  loadLiveData() {
    if(this.props.isVisible) {
      $.getJSON(this.props.liveDataUrl)
          .then((response) => this.handleLiveDataUpdate(response))
          .catch((error) => {
            console.error('There was an error loading the live map data:');
            console.error(error);
          });
    }
    else {
      window.setTimeout(() => this.loadLiveData(), UPDATE_INTERVAL);
    }
  }

  render() {
    return (
      <ProductMap
        drtArea={this.state.drtArea}
        intermodalArea={this.state.intermodalArea}
        boundingBox={this.state.boundingBox}
        calculatedRoute={this.state.calculatedRoute}
        stations={this.state.stations}
        places={this.state.places}
        rides={this.state.rides}
        vehicles={this.state.vehicles}
        vehicleCurrentPositions={this.state.vehicleCurrentPositions}
        trail={this.state.trail}
        vehicleLastPositions={this.state.vehicleLastPositions}
      />
    );
  }
}

LiveProductMapContainer.defaultProps = {};

LiveProductMapContainer.propTypes = {
  liveDataUrl: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired
};
