import React from 'react';
import ReactDOM from 'react-dom';
import SMSMessenger from '../../components/SMSMessenger';

const AdministrationSmsMessages = {
  init() {
    const smsContainer = window.document.getElementById('js-sms-global-view');
    if (!smsContainer) return;

    ReactDOM.render(<SMSMessenger />, smsContainer);
  }
}

export default AdministrationSmsMessages;
