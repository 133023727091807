/*eslint-disable */
// Source: https://gist.github.com/jieter/7689228#file-polyline-encoded-js-L82-L117
export const decodePolyline = (encoded, precision) => {
  var len = encoded.length;
  var index = 0;
  var latlngs = [];
  var lat = 0;
  var lng = 0;

  precision = Math.pow(10, -(precision || 5));

  while (index < len) {
    var b;
    var shift = 0;
    var result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var dlat = result & 1 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    shift = 0;
    result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var dlng = result & 1 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    latlngs.push([lat * precision, lng * precision]);
  }

  return latlngs;
};
/* eslint-enable */
