import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

export default class NumberOption extends React.Component {
  constructor(props) {
    super(props);

    this.handleNumberChange = this.handleNumberChange.bind(this);
  }

  handleNumberChange(event) {
    const { value } = event.target;
    this.props.handleUpdate(value === '' ? 0 : Math.trunc(value));
  }

  render() {
    return (
      <input
        className="form-control form-control-sm"
        type="number"
        value={isNil(this.props.value) ? '' : this.props.value}
        min={0}
        onChange={this.handleNumberChange}
        disabled={this.props.disabled}
      />
    );
  }
}

NumberOption.defaultProps = {
    disabled: false
};

NumberOption.propTypes = {
    value: PropTypes.number,
    handleUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

