import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ConversationItem from './ConversationItem';
import { timeDiff } from '../../lib/view_helpers';

export default class ConversationList extends Component {
  componentDidMount() {
    if (!this.props.activeConversation) {
      this.props.handleConversationChange(this.firstConversation);
    }
  }

  componentDidUpdate() {
    const domElement = ReactDOM.findDOMNode(this);
    domElement.scrollTop = 0;
  }

  renderItems() {
    const { conversations, activeConversation } = this.props;
    const conversationKeys = Object.keys(conversations);

    conversationKeys.sort((a, b) => {
      const convA = conversations[a];
      const convB = conversations[b];

      if (activeConversation && convA.number === activeConversation.number) {
        return -1;
      }
      if (activeConversation && convB.number === activeConversation.number) {
        return 1;
      }
      if (convA.unread > 0 && convB.unread === 0) {
        return -1;
      }
      if (convA.unread === 0 && convB.unread > 0) {
        return 1;
      }
      if( convA.last_message > convB.last_message) {
        return -1;
      }
      if( convA.last_message <= convB.last_message) {
        return 1;
      }

      return timeDiff(convA.last_message, convB.last_message);
    });

    this.firstConversation = conversationKeys[0];

    return conversationKeys.map((itemKey) => {
      const item = conversations[itemKey];
      const active = !!this.props.activeConversation && item.number === this.props.activeConversation.number;

      return (
        <ConversationItem {...item} key={item.number} active={active} onClick={this.props.handleConversationChange} />
      );
    });
  }

  render() {
    return <ul className="nav nav-tabs nav-tabs-classic conversations">{this.renderItems()}</ul>;
  }
}

ConversationList.propTypes = {
  handleConversationChange: PropTypes.func.isRequired,
  activeConversation: PropTypes.object,
  conversations: PropTypes.object
};
