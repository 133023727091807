import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

import RoutingTestsMap from '../../components/here_maps/RoutingTestsMap';

import loadData from '../../lib/bootstrapped_data';

const AdministrationRoutingTests = {
  init() {
    this.renderRoutingTestsMap();
  },

  renderRoutingTestsMap() {
    const mapEl = $('#js-map-container');

    const boundingBox = loadData('bbox');
    const routingTest = loadData('routing_test').data;
    const restrictedAreas = loadData('restricted_areas').data;

    if (!mapEl.length) return;
    ReactDOM.render(
      <RoutingTestsMap
        boundingBox={boundingBox}
        routingTest={routingTest}
        restrictedAreas={restrictedAreas}
      />,
      mapEl.get(0)
    );
  }
}

export default AdministrationRoutingTests;
