import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Tooltip } from 'react-leaflet';
import { DivIcon, LatLng } from 'leaflet';

export default class SVGMarker extends React.Component {
  render() {
    const svg = new DOMParser().parseFromString(this.props.icon, 'image/svg+xml');
    svg.style = this.props.styles;
    const colorize = svg.querySelector('.colorize');
    if (colorize) {
      colorize.style.fill = this.props.color;
    }

    let iconSize;
    let iconAnchor;

    if (this.props.size instanceof Array) {
      iconSize = this.props.size;
    } else {
      iconSize = [this.props.size, this.props.size];
    }

    if (!this.props.anchor) {
      iconAnchor = [iconSize[0] / 2, iconSize[1]];
    } else {
      iconAnchor = this.props.anchor;
    }

    const icon = new DivIcon({
      html: svg.documentElement.outerHTML,
      iconSize,
      iconAnchor,
      className: this.props.cssClass
    });

    return (
      <Marker position={this.props.position} icon={icon} zIndexOffset={800} type={this.props.markerType}>
        {this.props.title && (
          <Tooltip direction="top" offset={[0, iconSize[1] * -1]}>
            {this.props.title}
          </Tooltip>
        )}
      </Marker>
    );
  }
}

SVGMarker.defaultProps = {
  styles: {},
  size: 24,
  cssClass: 'marker'
};

SVGMarker.propTypes = {
  position: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(LatLng),
    PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })
  ]).isRequired,
  icon: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string,
  styles: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  anchor: PropTypes.arrayOf(PropTypes.number),
  cssClass: PropTypes.string,
  markerType: PropTypes.string.isRequired
};
