import React from 'react';
import PropTypes from 'prop-types';
import PassengerSelector from './PassengerSelector';
import RideOption from './RideOption';

export default class RideOptions extends React.Component {
  constructor(props) {
    super(props);

    this.t = window.locales.BookingWizard.RideOptions;
  }

  valueForOption(slug) {
    if (!this.props.options || !this.props.options.length ) { return false }

    return this.props.options.find((option) =>
      option.slug === slug
    ).value;
  }

  renderPassengerSelectors() {
    const arrayForPassengers = [...Array(this.props.passengers.length).keys()];
    return arrayForPassengers.map((index) => (
      <PassengerSelector
        key={`passenger-${index}`}
        currentPassenger={this.props.passengers[index]}
        passengerIndex={index}
        handleUpdate={this.props.handlePassengerUpdate}
        availablePassengerOptions={this.props.passengerOptions}
        availablePassengerTypes={this.props.passengerTypes}
        nameRequiredIfNoPublicTransportTicket={this.props.nameRequired}
      />
    ));
  }

  renderAddPassengerButton() {
    return (
      <li className="list-group-item">
        <button className="col-2 btn btn-primary" onClick={this.props.handleAddPassenger} type="button">
          {window.locales.BookingWizard.buttons.addPassenger}
        </button>
        <span>
          &nbsp;
          {window.locales.BookingWizard.buttons.removePassenger}
        </span>
      </li>
    );
  }

  renderPassengerOptions() {
    if (!this.props.passengerOptions) { return null;}

    return (
      <ul id="passenger-options" className="list-group">
        <li className="list-group-item">
          <div className="row">
            <div className="col-1 pr-0" />
            <div className="col-2 p-0">{this.t.passenger}</div>
            <div className="col-9 col-xl">
              <div className="container">
                <div className="row">
                  {
                    this.props.passengerOptions.map((passengerOption) => {
                      const optionName = passengerOption.localized_name;
                      const keyProp = `passenger-option-${optionName}`;
                      return <div className="col d-none d-xl-block pr-0" key={keyProp}>{optionName}</div>;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </li>
        {this.renderPassengerSelectors()}
        {this.renderAddPassengerButton()}
      </ul>
    );
  }


  renderRideOptionSelectors() {
    return (this.props.dynamicRideOptions.map((rideOption) => (
       <li className="list-group-item" key={rideOption.slug}>
        {rideOption.localized_name}

        <span className="float-right">
          <RideOption
            key={`option-${rideOption.slug}`}
            option={rideOption}
            value={this.valueForOption(rideOption.slug)}
            handleUpdate={this.props.handleOptionUpdate}
            disabled={!rideOption.bookable}
          />
        </span>
      </li>
    )));
  }

  renderRideOptions(){
    if (!this.props.dynamicRideOptions || this.props.dynamicRideOptions.length === 0) { return null;}

    return (
      <ul id="product-ride-options" className='list-group'>
        <li className='list-group-item'>
          <div className='row'>
            <div className="col-1 pr-0" />
            <div className="col-2 p-0 col-form-label">{window.locales.BookingWizard.option}</div>
            <div className="col-9 col-xl"><span className="float-right">{window.locales.BookingWizard.value}</span></div>
          </div>
        </li>
        {this.renderRideOptionSelectors()}
      </ul>
    )
  }

  render() {
    return (
      <>
        {this.renderPassengerOptions()}
        <ul className="list-group mt-3">
          {this.renderRideOptions()}
        </ul>
      </>
    );
  }
}

RideOptions.propTypes = {
  handlePassengerUpdate: PropTypes.func,
  handleAddPassenger: PropTypes.func,
  handleOptionUpdate: PropTypes.func,
  nameRequired: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ),
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
          ])
        })
      )
    })
  ),
  dynamicRideOptions: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      localized_name: PropTypes.string,
      data_type: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ),
  passengerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      localized_name: PropTypes.string,
      type: PropTypes.string
    })
  ),
  passengerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      data_type: PropTypes.string,
      name: PropTypes.string,
      localized_name: PropTypes.string
    })
  )
};
