import React from 'react';
import PropTypes from 'prop-types';
import { Polyline } from 'react-leaflet';

import originIcon from '../../images/maps/svg_icons/origin.svg';
import destinationIcon from '../../images/maps/svg_icons/destination.svg';
import pickupIcon from '../../images/maps/svg_icons/pickup.svg';
import dropoffIcon from '../../images/maps/svg_icons/dropoff.svg';
import SVGMarker from './markers/SVGMarker';
import { decodePolyline } from '../../lib/decode_polyline';
import { latLngFromObj, isEqualCoordinate } from '../../lib/map_helpers';
import { pointForDisplayAsString } from '../../lib/view_helpers';

export default class Ride extends React.Component {
  userName() {
    return [this.props.ride.user.first_name, this.props.ride.user.last_name].join(' ');
  }

  renderOrigin() {
    if (this.props.ride.pickup && isEqualCoordinate(this.props.ride.origin, this.props.ride.pickup)) {
      return null;
    }

    return (
      <SVGMarker
        key={this.props.ride.origin.id}
        icon={originIcon}
        color={this.props.color}
        position={latLngFromObj(this.props.ride.origin)}
        markerType="origin"
        size={10}
        title={`${window.locales.HereMap.origin}: ${this.userName()} - ${pointForDisplayAsString(
          this.props.ride.origin
        )}`}
      />
    );
  }

  renderDestination() {
    if (this.props.ride.dropoff && isEqualCoordinate(this.props.ride.destination, this.props.ride.dropoff)) {
      return null;
    }

    return (
      <SVGMarker
        key={this.props.ride.destination.id}
        icon={destinationIcon}
        color={this.props.color}
        position={latLngFromObj(this.props.ride.destination)}
        markerType="origin"
        size={10}
        title={`${window.locales.HereMap.destination}: ${this.userName()} - ${pointForDisplayAsString(
          this.props.ride.destination
        )}`}
      />
    );
  }

  renderPickup() {
    if (!this.props.ride.pickup) return null;

    return (
      <SVGMarker
        icon={pickupIcon}
        color={this.props.color}
        position={latLngFromObj(this.props.ride.pickup)}
        markerType="pickup"
        size={[31, 45]}
        anchor={[15, 45]}
        title={`${window.locales.HereMap.pickup}: ${this.userName()} - ${pointForDisplayAsString(
          this.props.ride.pickup
        )}`}
      />
    );
  }

  renderDropoff() {
    if (!this.props.ride.dropoff) return null;

    return (
      <SVGMarker
        icon={dropoffIcon}
        color={this.props.color}
        position={latLngFromObj(this.props.ride.dropoff)}
        markerType="dropoff"
        size={[31, 45]}
        anchor={[15, 45]}
        title={`${window.locales.HereMap.dropoff}: ${this.userName()} - ${pointForDisplayAsString(
          this.props.ride.dropoff
        )}`}
      />
    );
  }

  renderOriginToPickupRoute() {
    if (!this.props.ride.pickup || !this.props.ride.pickup.walking_track) return null;

    return (
      <Polyline
        positions={decodePolyline(this.props.ride.pickup.walking_track)}
        color={this.props.color}
        opacity={0.8}
        dashArray={[5, 5]}
        weight={3}
      />
    );
  }

  renderDropoffToDestinationRoute() {
    if (!this.props.ride.dropoff || !this.props.ride.dropoff.walking_track) return null;

    return (
      <Polyline
        positions={decodePolyline(this.props.ride.dropoff.walking_track)}
        color={this.props.color}
        opacity={0.8}
        dashArray={[5, 5]}
        weight={3}
      />
    );
  }

  renderRoute() {
    if (!this.props.ride.pickup || !this.props.ride.dropoff) return null;

    const path = [latLngFromObj(this.props.ride.pickup), latLngFromObj(this.props.ride.dropoff)];

    return <Polyline positions={path} color={this.props.color} weight={3} />;
  }

  render() {
    return (
      <>
        {this.renderOrigin()}
        {this.renderDestination()}
        {this.renderRoute()}
        {this.renderOriginToPickupRoute()}
        {this.renderDropoffToDestinationRoute()}
        {this.renderPickup()}
        {this.renderDropoff()}
      </>
    );
  }
}

Ride.defaultProps = {
  color: '#333'
};

Ride.propTypes = {
  ride: PropTypes.shape({
    id: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    origin: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }).isRequired,
    destination: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }).isRequired,
    pickup: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      walking_track: PropTypes.string
    }),
    dropoff: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      walking_track: PropTypes.string
    })
  }).isRequired,
  color: PropTypes.string
};
