import React from 'react';
import PropTypes from 'prop-types';
import BooleanOption from './BooleanOption';
import TextOption from './TextOption';
import NumberOption from './NumberOption';

export default class PassengerOption extends React.Component {
  constructor(props) {
    super(props);

    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  handleOptionChange(value) {
    const optionSlug = this.props.option.slug;
    this.props.handleUpdate(optionSlug, value);
  }

  getComponentClass() {
    switch (this.props.option.data_type) {
      case 'boolean':
        return BooleanOption;
      case 'string':
        return TextOption;
      case 'integer':
        return NumberOption;
      default:
        return BooleanOption;
    }
  }

  render() {
    const { option } = this.props;
    const SpecificOptionClass = this.getComponentClass(option);

    return (
      <div className="col d-none d-xl-block pr-0">
        <SpecificOptionClass
          value={this.props.value}
          handleUpdate={this.handleOptionChange}
          disabled={this.props.disabled}
          optionSlug={this.props.option.slug}        // at least for debugging purposes
          passengerIndex={this.props.passengerIndex} // at least for debugging purposes
        />
      </div>
    );
  }
}
PassengerOption.propTypes = {
    disabled: false
};

PassengerOption.propTypes = {
    option: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
    handleUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    passengerIndex: PropTypes.number // for debugging purposes
};
