import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import { format as formatCurrency, findCurrency } from 'currency-formatter';
import Card from '../bootstrap/Card';
import { secondsToHuman, timeDiff, formattedShortTime, pointForDisplay } from '../../lib/view_helpers';
import { rideDuration } from '../../lib/booking_helpers';

export default class RideDetails extends React.Component {
  constructor(props) {
    super(props);
    this.t = window.locales.BookingWizard.RideDetails;
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover(
      {
        placement : 'bottom',
        html : true,
        trigger: 'focus hover'
      }
    );
  }

  formattedPrice() {
    if (this.props.ride.fare.booking_price) {
      const { booking_price: bookingPrice, currency } = this.props.ride.fare;
      const currencyObject = findCurrency(currency);

      if (currencyObject) {
        const price = bookingPrice / 10 ** currencyObject.decimalDigits;
        return formatCurrency(price, { code: currency });
      }

      return `${bookingPrice} ${currency}`;
    }

    return this.t.freeRide;
  }

  driverDetails() {
    if (!this.props.ride.driver) {
      return this.t.unknownDriver;
    }

    return `${this.props.ride.driver.first_name} ${this.props.ride.driver.last_name}`;
  }

  renderWalkToPickup() {
    if (this.props.ride.pickup.walking_duration > 0) {
      return (
        <dl className="row">
          <dt className="col-sm-3 text-right">{this.t.origin}</dt>
          <dd className="col-sm-9">{pointForDisplay(this.props.ride.origin)}</dd>
          <dt className="col-sm-3 text-right">{this.t.walkingTime}</dt>
          <dd className="col-sm-9">
            {secondsToHuman(this.props.ride.pickup.walking_duration)} {this.t.minutes}
          </dd>
        </dl>
      );
    }

    return null;
  }

  renderWalkToDestination() {
    if (this.props.ride.dropoff.walking_duration > 0) {
      return (
        <dl className="row">
          <dt className="col-sm-3 text-right">{this.t.walkingTime}</dt>
          <dd className="col-sm-9">
            {secondsToHuman(this.props.ride.dropoff.walking_duration)} {this.t.minutes}
          </dd>
          <dt className="col-sm-3 text-right">{this.t.destination}</dt>
          <dd className="col-sm-9">{pointForDisplay(this.props.ride.destination)}</dd>
        </dl>
      );
    }

    return null;
  }

  renderStationInfo(station) {
    if ( !station ) {
      return '';
    }

    let dataContent = ""
    if (station.description) {
      dataContent += `<p>${station.description}</p>`
    }
    if (station.avatar) {
      dataContent += `<img alt='${this.t.stationInfo}' src='${station.avatar.versions.small.url}' width='100%'/>`
    }

    return (
      <p>
        <button className='btn btn-info' type="button"
                data-toggle="popover"
                data-html="true"
                data-title={station.location_name}
                data-content={dataContent} >
          {this.t.stationInfo}
        </button>
      </p>
    );
  }

  renderPickup() {
    let deviationClass = '';

    if (this.props.requestedTime && this.props.requestedTimeForWaypoint === 'origin') {
      const timeDeviation = timeDiff(this.props.ride.pickup.time, this.props.requestedTime);
      if (timeDeviation >= 10 * 60) {
        deviationClass = 'text-warning';
      }
    }

    return (
      <>
        <h2 className={deviationClass}>
          <p>
            <strong>
              {formattedShortTime(this.props.ride.pickup.negotiation_time, this.props.timezone.identifier)}
            </strong>
            {' - '}
            {formattedShortTime(this.props.ride.pickup.negotiation_time_max, this.props.timezone.identifier)}
          </p>
          <p>{pointForDisplay(this.props.ride.pickup)}</p>
        </h2>
        {this.renderWalkToPickup()}
        {this.renderStationInfo(this.props.ride.pickup.station)}
      </>
    );
  }

  renderDropoff() {
    let deviationClass = '';

    if (this.props.requestedTime && this.props.requestedTimeForWaypoint === 'destination') {
      const timeDeviation = timeDiff(this.props.ride.dropoff.time, this.props.requestedTime);

      if (timeDeviation >= 10 * 60) {
        deviationClass = 'text-warning';
      }
    }

    return (
      <>
        <h2 className={deviationClass}>
          <strong>
            {formattedShortTime(this.props.ride.dropoff.negotiation_time, this.props.timezone.identifier)}
          </strong>
          {' - '}
          {formattedShortTime(this.props.ride.dropoff.negotiation_time_max, this.props.timezone.identifier)}
          <p>{pointForDisplay(this.props.ride.dropoff)}</p>
        </h2>
        {this.renderWalkToDestination()}
        {this.renderStationInfo(this.props.ride.dropoff.station)}
      </>
    );
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col pickup">
            <Card title={this.t.pickup}>{this.renderPickup()}</Card>
          </div>
          <div className="col dropoff">
            <Card title={this.t.dropoff}>{this.renderDropoff()}</Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Card title={this.t.rideDetails}>
              <dl className="row">
                <dt className="col-sm-3 text-right">{this.t.rideDuration}</dt>
                <dd className="col-sm-9">
                  {secondsToHuman(rideDuration(this.props.ride))} {this.t.minutes}
                </dd>
                <dt className="col-sm-3 text-right">{this.t.driver}</dt>
                <dd className="col-sm-9">{this.driverDetails()} </dd>
                <dt className="col-sm-3 text-right">{this.t.vehicle}</dt>
                <dd className="col-sm-9">
                  {this.props.ride.vehicle.nickname} {` (${this.props.ride.vehicle.license_plate})`}
                </dd>
                <dt className="col-sm-3 text-right">{this.t.price}</dt>
                <dd className="col-sm-9">{this.formattedPrice()}</dd>
              </dl>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

RideDetails.propTypes = {
  ride: PropTypes.object.isRequired,
  requestedTime: PropTypes.any,
  requestedTimeForWaypoint: PropTypes.oneOf(['origin', 'destination']).isRequired,
  timezone: PropTypes.shape({
    identifier: PropTypes.string,
    utc_offset: PropTypes.number
  }).isRequired
};
