import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import { compact, sortBy } from 'lodash';

import loadData from '../../lib/bootstrapped_data';
import Area from '../../components/here_maps/layers/Area';
import HereMap from '../../components/here_maps/HereMap.jsx';
import { setupRecurrenceRulesFormButtons } from '../../lib/form_helpers';

const AdministrationAreas = {
  init() {
    setupRecurrenceRulesFormButtons();
    this.renderMaps();
  },
  renderArea() {
    const areas = sortBy(compact(loadData('areas').data), "z_index");
    if (!areas && areas.length === 0) return null;

    return areas.map((area) => <Area key={`area-${area.id}`}
                                     area={area.area}
                                     color={area.color}
                                     opacity={area.opacity}
                                     weight={area.stroke_weight}
                                     fillColor={area.fill_color}
                                     fillOpacity={area.fill_opacity} />);
  },
  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;

    ReactDOM.render(
        <HereMap noTraffic boundingBox={loadData('bbox')}>
          {this.renderArea()}
        </HereMap>,
        mapEl.get(0)
    );
  }
}

export default AdministrationAreas;
