import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import IncomingMessage from './IncomingMessage';
import OutgoingMessage from './OutgoingMessage';

export default class MessageList extends Component {
  componentDidUpdate() {
    const domElement = ReactDOM.findDOMNode(this);
    domElement.scrollTop = domElement.scrollHeight;
  }

  renderMessages() {
    const {timezone} = this.props;
    return this.props.messages.map((message) => {
      if (message.direction === 'incoming') {
        return <IncomingMessage message={message} key={message.id} timezone={timezone}/>;
      }
      return <OutgoingMessage message={message} key={message.id} timezone={timezone}/>;
    });
  }

  render() {
    return (
      <div className="chat-messages">
        <ul>{this.renderMessages()}</ul>
      </div>
    );
  }
}

MessageList.propTypes = {
  messages: PropTypes.array,
  timezone: PropTypes.object
};
