import React from 'react';
import PropTypes from 'prop-types';
import BooleanSwitch from './BooleanSwitch';

export default class BooleanOption extends React.Component {
  render() {
    return (
      <BooleanSwitch
        value={this.props.value}
        handleUpdate={this.props.handleUpdate}
        disabled={this.props.disabled}
        classNameContainer="h-100 d-none d-xl-block"
        classNameLabel="h-100 d-inline-flex align-items-center"
      />
    );
  }
}

BooleanOption.defaultProps = {
    disabled: false
};

BooleanOption.propTypes = {
    value: PropTypes.bool,
    handleUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};
