import $ from 'jquery';

export const getProductIdFromURL = () => {
  const path = window.location.pathname.split('/');
  const productIdIndex = path.indexOf('products') + 1;
  return path[productIdIndex];
};

export const extractCSRFTokenFromPage = () => $('meta[name="csrf-token"]').prop('content');
export const extractCSRFParamFromPage = () => $('meta[name="csrf-param"]').prop('content');

export const buildFormForHttpMethod = (method, href) => {
  const formString = `<form action="${href}" method="POST">
    <input type="hidden" value="${extractCSRFTokenFromPage()}" name="${extractCSRFParamFromPage()}" />
    <input type="hidden" value="${method.toUpperCase()}" name="_method" />
  </form>`;

  return $(formString);
};

export const uniqueIdentifierFromCalculatedPoint = (calculatedPoint) => {
  if (calculatedPoint.station_id) {
    return calculatedPoint.station_id;
  }

  return [
    calculatedPoint.location_name,
    calculatedPoint.street_name,
    calculatedPoint.street_number,
    calculatedPoint.postal_code,
    calculatedPoint.city
  ].join(', ');
};
