import $ from 'jquery';

const AdministrationProvidersRidePaymentRecoveries = {
  init() {
    this.refundForm();
  },

  refundForm() {
    const moneyToggler = $('.js-toggle-money-input');
    if (!moneyToggler.length) return;

    $('.js-toggle-money-input').click((e) => {
      const target = $(e.target);
      const moneyInput = target.parents('tr').find('.js-money-input');
      const moneyRenderedInput = target.parents('tr').find('.js-rendered-money-input');

      moneyInput.prop('disabled', !target.prop('checked'));
      moneyRenderedInput.prop('disabled', !target.prop('checked'));
    });
  }
}

export default AdministrationProvidersRidePaymentRecoveries;
