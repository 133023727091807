import { pick, omit, isNil } from 'lodash';
import { timeDiff, pointForDisplay } from './view_helpers';

const validParamsForPoint = [
  'lat',
  'lng',
  'location_name',
  'street_name',
  'street_number',
  'postal_code',
  'city',
  'county',
  'country',
  'station_id'
];

const excludePlaceTypesForPlaceName = [
  'administrative_area_level_1',
  'administrative_area_level_2',
  'administrative_area_level_3',
  'administrative_area_level_4',
  'administrative_area_level_5',
  'country',
  'floor',
  'locality',
  'postal_code',
  'postal_code_prefix',
  'postal_code_suffix',
  'postal_town',
  'sublocality',
  'sublocality_level_1',
  'sublocality_level_2',
  'sublocality_level_3',
  'sublocality_level_4',
  'sublocality_level_5'
]

const buildRequestedPoint = (options) => {
  const { type, params, requestedTimeForWaypoint, requestedTime } = options;
  const requestedPoint = pick(params, validParamsForPoint);

  if (type === requestedTimeForWaypoint && requestedTime) {
    requestedPoint.time = requestedTime;
  }

  if (!isNil(params) && params.type === 'station' && params.id) {
    requestedPoint.station_id = params.id;
  }

  return requestedPoint;
};

const cleanExistingWaypoint = (waypoint) => omit(waypoint, ['id', 'created_at', 'updated_at', 'waypoint_type', 'type']);

const rideDuration = (ride) => timeDiff(ride.dropoff.time, ride.pickup.time);

const locationNameFromWaypoint = (waypoint) => (waypoint ? waypoint.location_name : undefined);

const stationIdFromWaypoint = (waypoint) => {
  if (waypoint && waypoint.station_id) {
    // RequestedPoint
    return waypoint.station_id;
  }

  if (waypoint && waypoint.type === 'station' && waypoint.id) {
    // Station
    return waypoint.id;
  }

  return undefined;
};

const mapStationsToSelectOptions = (stations) =>
  stations.map((station) => ({
    label: station.location_name,
    value: station.id,
    type: station.type
  }));

const mapRequestedPointsToSelectOptions = (requestedPoints) =>
  requestedPoints.map((point) => ({
    label: pointForDisplay(point),
    value: point.id,
    type: point.type
  }));

const buildSelectOptionFromUser = (user) => {
  if (!user) {
    return [];
  }

  return [{ label: `${user.first_name} ${user.last_name} (${user.phone_number})`, value: user.id }];
};

const extractValidationErrors = (errorCollection, modelName) => {
  const errorObject = errorCollection.find((item) => item.model === modelName);

  return errorObject ? errorObject.errors : {};
};

const addressComponentByType = (place, type) => {
  const components = place.address_components;
  const filtered = components.filter((c) => c.types.includes(type));

  if (filtered.length === 0) return null;

  return filtered[0].long_name;
};

const determinePlaceName = (place) => {
  const isValidPlaceType = (type) => !excludePlaceTypesForPlaceName.includes(type);

  if (place.types.some((type) => isValidPlaceType(type))) {
    return place.name;
  }
  return null;
};

const addressInputValueFromWaypoint = (point) => {
  if (!point || point.station_id || (point.type === 'station' && point.id)) {
    return '';
  }

  return pointForDisplay(point);
};

// This generates a random string that can be used in the ID and name tags for
// components that are used multiple times in a page (like bool & waypoint)
const generateComponentIdentifier = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export {
  generateComponentIdentifier,
  mapStationsToSelectOptions,
  locationNameFromWaypoint,
  stationIdFromWaypoint,
  cleanExistingWaypoint,
  buildRequestedPoint,
  rideDuration,
  buildSelectOptionFromUser,
  extractValidationErrors,
  addressComponentByType,
  determinePlaceName,
  addressInputValueFromWaypoint,
  mapRequestedPointsToSelectOptions
};
