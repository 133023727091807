import React, { Component } from 'react';
import loadData from '../lib/bootstrapped_data';
import ConversationList from './sms/ConversationList';
import MessageList from './sms/MessageList';
import NewMessageInput from './sms/NewMessageInput';
import MessageHeader from './sms/MessageHeader';
import StellwerkAPI from '../lib/stellwerk_api';
import { getProductIdFromURL } from '../lib/generic_helpers';

export default class SMSMessenger extends Component {
  constructor(props) {
    super(props);

    this.stellwerkAPI = new StellwerkAPI(getProductIdFromURL());

    const conversations = loadData('conversations');
    this.timezone = loadData('timezone')
    this.state = { conversations, messages: [] };

    this.sendNewMessage = this.sendNewMessage.bind(this);
    this.markConversationAsRead = this.markConversationAsRead.bind(this);
    this.handleConversationChange = this.handleConversationChange.bind(this);
  }

  sendNewMessage(content) {
    const { number } = this.state.activeConversation;
    const { messages } = this.state;

    this.stellwerkAPI
      .sendSmsMessage(number, content)
      .done((response) => {
        messages.push(response.data);
        this.setState({ messages });

        this.markConversationAsRead();
      })
      .fail((response) => {
        console.log('could not send SMS', response);
      });
  }

  markConversationAsRead() {
    const { number } = this.state.activeConversation;
    const { conversations } = this.state;

    if (conversations[number].unread === 0) {
      return;
    }

    this.stellwerkAPI.markSmsConversationAsRead(number).done(() => {
      conversations[number].unread = 0;
      this.setState({ conversations });
    });
  }

  currentConversationHasUnreads() {
    return this.state.activeConversation && this.state.conversations[this.state.activeConversation.number].unread > 0;
  }

  handleConversationChange(number) {
    this.stellwerkAPI.getSmsMessagesForNumber(number).done((response) => {
      this.setState({
        activeConversation: this.state.conversations[number],
        messages: response.data
      });
    });
  }

  render() {
    return (
      <div className="tab-container tab-left">
        <ConversationList
          handleConversationChange={this.handleConversationChange}
          conversations={this.state.conversations}
          activeConversation={this.state.activeConversation}
        />
        <div className="tab-content">
          <div className="sms-chat">
            <div className="chat-window">
              <MessageHeader conversation={this.state.activeConversation} />
              <MessageList messages={this.state.messages} timezone={this.timezone}/>
              <NewMessageInput
                onSubmit={this.sendNewMessage}
                markAsRead={this.markConversationAsRead}
                unreads={this.currentConversationHasUnreads()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
