import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

import loadData from '../../lib/bootstrapped_data';

import { setupRecurrenceRulesFormButtons } from '../../lib/form_helpers';
import HereMap from '../../components/here_maps/HereMap.jsx';
import Line from '../../components/here_maps/Line.jsx';

const AdministrationLines = {
  init() {
    this.renderMaps();
    setupRecurrenceRulesFormButtons();
  },
  renderMaps() {
    const mapEl = $('#js-map-container');
    if (!mapEl.length) return;

    const lineData = loadData('line');
    const linesData = loadData('lines');

    let lines = [];

    if (linesData && linesData.data) {
      lines = linesData.data;
    } else if (lineData && lineData.data) {
      lines = [lineData.data];
    }
    if (!lines.length) return;

    ReactDOM.render(
      <HereMap autoZoom noTraffic boundingBox={loadData('bbox')}>
        {lines.map((line) => (
          <Line key={line.id} line={line} />
        ))}
      </HereMap>,

      mapEl.get(0)
    );
  }
}

export default AdministrationLines;
