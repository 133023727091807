import $ from 'jquery';

const AdministrationDriverMulticasts = {
  init() {
    this.driverSelectButtons();
  },

  driverSelectButtons() {
    const checkAllButton = $('.js-check-all-drivers');
    const unCheckAllButton = $('.js-uncheck-all-drivers');
    if (!checkAllButton.length && !unCheckAllButton.length) return;

    const selectBoxes = $('.js-select-driver:enabled');

    checkAllButton.on('click', () => {
      selectBoxes.prop('checked', true);
    });

    unCheckAllButton.on('click', () => {
      selectBoxes.prop('checked', false);
    });
  }
}

export default AdministrationDriverMulticasts;
