import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Polyline, Tooltip } from 'react-leaflet';
import { DivIcon } from 'leaflet';
import { latLngFromObj } from '../../lib/map_helpers';
import { formattedTime } from '../../lib/view_helpers';

import 'leaflet-rotatedmarker/leaflet.rotatedMarker.js';

const pathStyles = {
  color: '#888',
  opacity: 0.5,
  weight: 2
};
const size = 24;

const icon = new DivIcon({
  html: `<span style="color: ${pathStyles.color}; opacity: ${pathStyles.opacity}; filter: brightness(0.45);">▲</span>`,
  iconSize: [size, size],
  iconAnchor: [size / 2, size / 2],
  className: 'breadcrumb-marker text-center'
});

export default class VehicleTrail extends React.Component {
  renderBreadcrumbs() {
    return this.props.positions.map((position) => (
      <Marker
        key={position.id}
        position={latLngFromObj(position)}
        type="vehicleBreadcrumb"
        icon={icon}
        rotationAngle={position.heading}
        rotationOrigin="center center"
      >
        <Tooltip direction="top" offset={[0, -size]}>
          {`${formattedTime(position.recorded_at)} UTC`}
        </Tooltip>
      </Marker>
    ));
  }

  render() {
    return (
      <>
        <Polyline positions={this.props.positions.map((pos) => latLngFromObj(pos))} {...pathStyles} />
        {this.renderBreadcrumbs()}
      </>
    );
  }
}

VehicleTrail.defaultProps = {};

VehicleTrail.propTypes = {
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      heading: PropTypes.number,
      recorded_at: PropTypes.string
    })
  ).isRequired
};
